import { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

const ClosableModelComp = (props) => {
    const [show, setShow] = useState(false);
    const { message, setupdatesuccess } = props
    useEffect(() => {
        const { showValue } = props
        setShow(showValue)
        setTimeout(() => {
            handleClose()
        }, 5000)
    }, [props])
    const handleClose = () => setupdatesuccess(false);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Footer>
                    <Modal.Body>{message}</Modal.Body>
                    <Button className='primary-btn' size='sm' variant="primary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { ClosableModelComp }