import React, { useEffect, useState } from "react";
// import { Container , Button, Table} from 'react-bootstrap';
import { getallCollections, UpdateBundel } from "../../../apis";
import "./collection.css";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Row, Col } from "react-bootstrap";
import { SpinnerComp } from "../../../pages/spinner";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaEye } from "react-icons/fa";
import Switch from "react-switch";
import { ClosableModelComp } from "../../../pages/popup/closableModle";
import { ConfirmationModleComp } from '../../../pages/popup/confirmationbox'


import { MdDelete } from 'react-icons/md'


const { SearchBar } = Search;
const ViewCollectionComp = () => {
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [checked, setchecked] = useState([]);
  const [reload, setReload] = useState('')
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [modelmessage, setmodelmessage] = useState('')
  const [confirmation, setConfirmation] = useState([false, ''])
  const [collections, setCollections] = useState([]);
  const [currentpageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setSizeperPage] = useState(10)
  useEffect(() => {
    setshowLoader(true);
    getallCollections().then((res) => {
      setCollections(res.data.response.result.data.bundles);
      setshowLoader(false);
    });
  }, [reload]);

  const handleChange = (id, isActive) => {
    const checkedIds = [...checked];
    const index = checkedIds.indexOf(id);
    if (index !== -1) {
      checkedIds.splice(index, 1);
    } else {
      checkedIds.push(id);
    }
    setchecked(checkedIds);

    const toggle = !isActive;

    UpdateBundel({ isActive: toggle }, id).then((res) => {
      if (res.status === 200) {
        setmodelmessage("Collection updated Successfully")
        setupdatesuccess(true)
        setReload(Math.random());
      }
    });
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const ids = collections.map((doc) => {
      if (doc.isActive) {
        return doc._id;
      }
    });
    const finalIds = ids.filter((id) => id !== undefined);
    setchecked(finalIds);
  }, [collections]);

  const formaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <div className="actions">
        <FaEye
          className="view-icon"
          onClick={() => {
            navigate("/dashboard/viewfiles", { state: { docs: row } });
          }}
        ></FaEye>
        <Switch
          key={row._id}
          onChange={() => handleChange(row._id, row.isActive)}
          checked={extraData.includes(row._id)}
          className="react-switch"
          width={40}
          height={20}
          onColor={'#925eb0'}
          activeBoxShadow={'0 0 2px 3px #9204b0'}
        />
        <FaEdit
          className="edit-icon"
          onClick={() =>
            navigate("/dashboard/addCollection", {
              state: { docs: [], id: row._id, isNew: false },
            })
          }
        />
        <MdDelete className='delete-icon' onClick={() => { deletUser(row._id) }} />
      </div>
    );
  };

  const deletUser = (id) => {
    setConfirmation([true, id])
  }

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPageNum(page)
      setSizeperPage(sizePerPage)
    },
    onPageChange: (page, sizePerPage) => {
      setCurrentPageNum(page)
      console.log(page)
      setSizeperPage(sizePerPage)

    }
  };

  const columns = [
    {
      dataField: 'sl.no',
      text: 'SN',
      formatter: (cell, row, rowIndex, page) => {
        const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
        return serialNum
      },
      // sort: true,
      headerStyle: {
        width: '40px'
      }
    },
    {
      dataField: "bundleTitle",
      text: "Title",
      style: {
        fontSize: '12px'
      },
      sort: true
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: " Actions",
      formatter: formaterHandler,
      formatExtraData: checked,
    },
  ];

  return (
    <>
      {confirmation[0] && <ConfirmationModleComp show={confirmation[0]} setReload={setReload} id={confirmation[1]} method={"collection"} message={"Do You want to Delete this Collection"} onHide={() => setConfirmation([false, ''])} />}
      {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={modelmessage} />}
      <SpinnerComp Show={showLoader} />
      <BsFillArrowLeftCircleFill
        className="back-button"
        onClick={() => navigate('/dashboard/manage')}
      />
      <span className='dashboard-title'>Collections</span>
      <ToolkitProvider
        keyField="id"
        data={collections}
        columns={columns}
        search
      >
        {(props) => (
          <div>

            <Row>
              <Col>
                <SearchBar {...props.searchProps} className="searchBar" />
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                <Button
                  variant="primary"
                  className="primary-btn"
                  size="sm"
                  onClick={() =>
                    navigate("/dashboard/addCollection", {
                      state: { docs: [], isNew: true },
                    })
                  }
                >
                  + Add Collection
                </Button>
              </Col>
            </Row>
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory(options)}
              striped
              hover
              condensed
              bootstrap4
              size="sm"
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export { ViewCollectionComp };
