import React, { useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import './sidebar.css'
import { NavLink, useLocation } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { useState } from 'react';


const SidebarComp = (props) => {
  const [showManageSubmenu, setShowSubManagemenu] = useState(false)
  const { passChild, currentState } = props
  const handelToggle = () => {

    if (currentState === false) {
      passChild(true)
    }
    else {
      passChild(false)
    }
  }


  const currentlocation = useLocation()
  const isOnmanage = currentlocation.pathname === `/dashboard/manage` || currentlocation.pathname === `/dashboard/viewfiles` || currentlocation.pathname === '/dashboard/viewkeywords' || currentlocation.pathname === '/dashboard/viewusers' || currentlocation.pathname === `/dashboard/viewcollections` || currentlocation.pathname === `/dashboard/uploadFiles` || currentlocation.pathname === `/dashboard/viewQuestions` || currentlocation.pathname === `/dashboard/addCollection` || currentlocation.pathname === `/dashboard/viewMessage`

  const handelManageSubmenu = () => {
    if (showManageSubmenu) {
      setShowSubManagemenu(false)
    }
    else {
      setShowSubManagemenu(true)
    }
  }

  useEffect(() => {
    setShowSubManagemenu(isOnmanage)

  }, [isOnmanage])


  return (
    <div className='sidebar-div' >
      <CDBSidebar data-foo={currentState} className="sidebar-content">
        {/* prefix={<i className="fa fa-bars fa-large"></i>} */}
        <CDBSidebarHeader className="sidebar-header" prefix={<i className="fa fa-bars fa-large" style={{ color: "black", fontSize: '1.5rem', marginRight: '-2rem', marginTop: '-1rem' }} onClick={handelToggle}></i>}  >
          <img
            alt="Societal"
            src="https://space.societalthinking.org/static/media/Space-logo.png"
            className="logo mouse-cursor"
            onClick={() => navigator("/")}
          />
        </CDBSidebarHeader>

        <CDBSidebarContent data={currentState} className="sidebar-content">
          <CDBSidebarMenu>

            <div className={currentlocation.pathname === '/dashboard/homepage' ? 'active' : ''} >
              <NavLink exact to="/dashboard/homepage" onlyActiveOnIndex >
                <CDBSidebarMenuItem icon="columns" className={currentlocation.pathname === '/dashboard/homepage' ? 'active-text' : ''}>Dashboard</CDBSidebarMenuItem>
              </NavLink>
            </div>


            <div className={isOnmanage ? 'active' : 'nothing'}>
              <NavLink exact to="/dashboard/manage" activeClassName='activeClicked' onlyActiveOnIndex >
                <CDBSidebarMenuItem icon="tools" className={isOnmanage ? 'active-text' : ''}  >Manage {showManageSubmenu ? <AiFillCaretDown onClick={() => { handelManageSubmenu() }} style={{ marginLeft: '5rem', fontSize: '15px' }} /> : <AiFillCaretUp onClick={() => { handelManageSubmenu() }} style={{ marginLeft: '5rem', fontSize: '15px' }} />}</CDBSidebarMenuItem>
              </NavLink>
            </div>

            <div className='manage-submenu'>
              {showManageSubmenu && <>
                <div className={currentlocation.pathname === '/dashboard/viewfiles' || currentlocation.pathname === `/dashboard/uploadFiles` ? 'active-submenu' : ''} >
                  <NavLink exact to='/dashboard/viewfiles'  >
                    <CDBSidebarMenuItem icon="file" className={currentlocation.pathname === '/dashboard/viewfiles' || currentlocation.pathname === `/dashboard/uploadFiles` ? 'active-text' : ''} >Documents</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                <div className={currentlocation.pathname === '/dashboard/viewkeywords' ? 'active-submenu' : ''}>
                  <NavLink exact to="/dashboard/viewkeywords" >
                    <CDBSidebarMenuItem icon="search" className={currentlocation.pathname === '/dashboard/viewkeywords' ? 'active-text' : ''} >Keywords</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                <div className={currentlocation.pathname === '/dashboard/viewusers' ? 'active-submenu' : ''}>
                  <NavLink exact to="/dashboard/viewusers" >
                    <CDBSidebarMenuItem icon="users" className={currentlocation.pathname === '/dashboard/viewusers' ? 'active-text' : ''} >Users</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                <div className={currentlocation.pathname === '/dashboard/viewcollections' ? 'active-submenu' : ''}>
                  <NavLink exact to="/dashboard/viewcollections" >
                    <CDBSidebarMenuItem icon="folder" className={currentlocation.pathname === '/dashboard/viewcollections' ? 'active-text' : ''} >Collections</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                <div className={currentlocation.pathname === '/dashboard/viewQuestions' ? 'active' : ''}>
                  <NavLink exact to="/dashboard/viewQuestions" >
                    <CDBSidebarMenuItem icon="table" className={currentlocation.pathname === '/dashboard/viewQuestions' ? 'active-text' : ''} >Questions</CDBSidebarMenuItem>
                  </NavLink>
                </div>
                <div className={currentlocation.pathname === '/dashboard/viewMessage' ? 'active' : ''}>
                  <NavLink exact to="/dashboard/viewMessage" >
                    <CDBSidebarMenuItem icon="inbox" className={currentlocation.pathname === '/dashboard/viewMessage' ? 'active-text' : ''} >Queries</CDBSidebarMenuItem>
                  </NavLink>
                </div>
              </>}
            </div>
            {/* 
            <div className={currentlocation.pathname === '/dashboard/report' ? 'active' : ''} >
              <NavLink exact to="/dashboard/report" activeClassName="activeClicked" onlyActiveOnIndex >
                <CDBSidebarMenuItem icon="chart-line" className={currentlocation.pathname === '/dashboard/report' ? 'active-text' : ''} >Reports</CDBSidebarMenuItem>
              </NavLink>
            </div> */}
            {/* 
            <div className={currentlocation.pathname === '/dashboard/profile' ? 'active' : ''} >
              <NavLink exact to="/dashboard/profile" activeClassName="activeClicked" onlyActiveOnIndex>
                <CDBSidebarMenuItem icon="user" className={currentlocation.pathname === '/dashboard/profile' ? 'active-text' : ''}  >Profile</CDBSidebarMenuItem>
              </NavLink>
            </div> */}

          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>

        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export { SidebarComp }