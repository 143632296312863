export const difference = (obj1, obj2) => {
    let newObj = {};
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        // Adding a key/value pair
        newObj[key] = obj2[key];
        return newObj;
      }
    });
    return newObj || -1;
  };

