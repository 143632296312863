
import Table from 'react-bootstrap/Table';

const ViewCollectionDetails = (props) => {
    const { data } = props
    const convertedDate = (new Date(data.docs.publishedDate).toLocaleDateString())

    return (
        <>
            <Table striped bordered hover size="lg">
                <tbody>
                    <tr>
                        <th>Title</th>
                        <td>{data.docs.bundleTitle}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{data.docs.description}</td>

                    </tr>
                    <tr>
                        <th>Keywords</th>
                        <td>{data.docs.keywords ? data.docs.keywords.join(' ') : []}</td>

                    </tr>

                    <tr>
                        <th>PublishedDate</th>
                        <td>{convertedDate}</td>
                    </tr>
                    <tr>
                        <th>PublishedBy</th>
                        <td>{data.docs.publishedBy}</td>

                    </tr>

                </tbody>
            </Table>
        </>
    )
}

export { ViewCollectionDetails }