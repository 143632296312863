import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import './popup.css'
const CenterModelComp = (props) => {
    const { message } = props
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header style={{ display: 'block' }}>
                    <Row>
                        <Col md={7}>
                            <span className='content-name'>UserName  : {message.name}</span>
                            <div className='content-email' >Email : {message.email}</div>
                        </Col>
                        <Col>
                            <span className='content-date' >Date  : {new Date(message.createdAt).toLocaleDateString()}  </span>
                            <div className='content-date' > Time   : {new Date(message.createdAt).toLocaleTimeString('en-US')}</div>
                        </Col>
                    </Row>
                </Modal.Header>

                <Modal.Body>
                    <h6>Message</h6>
                    <div className='modle-content'>
                        {message.message}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='primary-btn' size="sm" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { CenterModelComp }