import React, { useEffect, useState } from "react";
// import { Container , Button, Table} from 'react-bootstrap';
import {
    getAllQuestions,
    addQuestions,
    editQuestions,
} from "../../../apis";
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Row, Col, Modal, Form } from "react-bootstrap";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import Switch from "react-switch";
import { SpinnerComp } from "../../../pages/spinner";
import { ClosableModelComp } from "../../../pages/popup/closableModle";
import "./questions.css";

const { SearchBar } = Search;
const QuestionsComp = () => {
    const [checked, setchecked] = useState([]);
    const [showLoader, setshowLoader] = useState(false);
    const [reloadPage, setreloadPage] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [inputValue, setInputValue] = useState({});
    const [updatesuccess, setupdatesuccess] = useState(false)
    const [currentpageNum, setCurrentPageNum] = useState(1)
    const [pageSize, setSizeperPage] = useState(10)


    // const [mystate , setmystate] = useState(false)
    const handleChange = (id, isActive) => {

        const checkedIds = [...checked];
        const index = checkedIds.indexOf(id);
        if (index !== -1) {
            checkedIds.splice(index, 1);
        } else {
            checkedIds.push(id);
        }
        setchecked(checkedIds);

        const toggle = !isActive;
        editQuestions({ isActive: toggle }, id).then((res) => {

            if (res.status === 200) {
                setupdatesuccess(true);
                setreloadPage(Math.random());
            }
        });
    };

    const navigate = useNavigate();

    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        setshowLoader(true);
        getAllQuestions().then((res) => {
            const QuestionsData = res.data.response.result.data;
            setQuestions(QuestionsData ? QuestionsData : []);
            setshowLoader(false);
        });
    }, [reloadPage]);

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        const ids = questions.map((doc) => {
            if (doc.isActive) {
                return doc._id;
            }
        });
        const finalIds = ids.filter((id) => id !== undefined);
        setchecked(finalIds);
    }, [questions]);

    const ToggleformaterHandler = (cellContent, row, rowIndex, extraData) => {
        return (
            <>
                <Switch
                    key={row._id}
                    onChange={() => handleChange(row._id, row.isActive)}
                    checked={extraData.includes(row._id)}
                    className="react-switch"
                    width={40}
                    height={20}
                    onColor={'#925eb0'}
                    activeBoxShadow={'0 0 2px 3px #9204b0'}
                />
            </>
        );
    };

    const formaterHandler = (cellContent, row, rowIndex, extraData) => {
        return (
            <>
                {/* <FaEdit
                    className="edit-icon"
                    onClick={() =>
                        navigate("/dashboard/editQuestion", {
                            state: { id: row._id, question: row.question },
                        })
                    }
                /> */}
                <FaEdit className='edit-icon' onClick={(e) => callModel(row)} />
            </>
        );
    };

    const options = {
        onSizePerPageChange: (sizePerPage, page) => {
            setCurrentPageNum(page)
            setSizeperPage(sizePerPage)
        },
        onPageChange: (page, sizePerPage) => {
            setCurrentPageNum(page)
            console.log(page)
            setSizeperPage(sizePerPage)

        }
    };

    let columns = [
        {
            dataField: 'sl.no',
            text: 'SN',
            formatter: (cell, row, rowIndex, page) => {
                const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
                return serialNum
            },
            // sort: true,
            headerStyle: {
                width: '40px'
            }
        },
        {
            dataField: "question",
            text: "Questions",
            style: {
                fontSize: '12px'
            },
            sort: true,
        },
        {
            dataField: "",
            text: "IsActive",
            formatter: ToggleformaterHandler,
            formatExtraData: checked,
        },
        {
            dataField: "",
            text: " Actions",
            formatter: formaterHandler,
        },
    ];

    const callModel = (row) => {

        setInputValue(row);
        setModalShow(true);
    };

    const addcallModel = () => {
        setInputValue({});
        setModalShow(true);
    }

    return (
        <>
            {/* <h1>Hello</h1> */}
            {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={"Question updated Successfully"} />}
            <SpinnerComp Show={showLoader} />
            <BsFillArrowLeftCircleFill
                className="back-button"
                onClick={() => navigate("/dashboard/manage")}
            />
            <span className="dashboard-title">Questions</span>
            <ToolkitProvider
                keyField="id"
                data={questions}
                columns={columns}
                columnToggle
                search
            >
                {(props) => (
                    <div>

                        <Row>
                            <Col>
                                <SearchBar {...props.searchProps} className="searchBar" />
                            </Col>
                            <Col
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: "10px",
                                }}
                            >
                                <Button variant="primary" className="primary-btn" size="sm" onClick={addcallModel}>
                                    + Add Question
                                </Button>
                            </Col>
                        </Row>

                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory(options)}
                            striped
                            hover
                            condensed
                            bootstrap4
                        />
                    </div>
                )}
            </ToolkitProvider>

            <AddKeywordsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setreloadPage={setreloadPage}
                InputValue={inputValue}
            />
        </>
    );
};

const AddKeywordsModal = (props) => {
    const { setreloadPage, InputValue } = props;
    const isempty = !Object.values(InputValue).some(x => x !== null && x !== '');
    const [newQuestions, setNewQuestions] = useState("");
    const [success, setsuccess] = useState(false);
    const [disablebtn, setdisablebtn] = useState(false)
    const isNew = InputValue.length === 0;

    useEffect(() => {
        if (newQuestions === "") {
            setdisablebtn(true)
        }
        else {
            setdisablebtn(false)
        }
    }, [newQuestions, InputValue])

    useEffect(() => {
        setNewQuestions(InputValue.question)
    }, [InputValue])

    const submitQuestions = () => {
        if (isempty) {

            addQuestions(newQuestions).then((res) => {
                if (res.status === 200) {
                    setsuccess(true);
                    setreloadPage(Math.random());
                    setTimeout(() => {
                        setsuccess(false);
                        props.onHide();
                    }, 1000);
                }
            });
        } else {
            const data = { question: newQuestions }
            const id = InputValue._id
            editQuestions(data, id).then((res) => {
                if (res.status === 200) {
                    setsuccess(true);
                    setreloadPage(Math.random());
                    setTimeout(() => {
                        setsuccess(false);
                        props.onHide();
                    }, 1000);
                }
            })
        }
    };

    const Successmsg = () => {
        return (
            <span className="success">
                Question {isempty ? "added" : "updated"} Successfuly
            </span>
        );
    };

    const handelCancel = () => {
        setNewQuestions(InputValue.question)
        props.onHide()
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {isempty ? "Add Question" : "Edit Keywords"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                            Question <sup className="required-icon">*</sup>{" "}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            required
                            placeholder="Enter Question"
                            value={isempty ? InputValue.question : newQuestions}
                            onChange={(e) => setNewQuestions(e.target.value)}
                            size="sm"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {success ? Successmsg() : ""}
                <Button disabled={disablebtn} className="primary-btn" size="sm" onClick={submitQuestions}>{isNew ? "Add" : "Save"}</Button>
                <Button className="primary-btn" size="sm" onClick={handelCancel}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export { QuestionsComp };
