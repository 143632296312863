import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css'
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi'
const NavbarComp = () => {
  const navigate = useNavigate()
  const logout = async () => {

    await localStorage.removeItem('accessTokenAdmin')
    navigate('/', { state: { logout: true } })
  }
  return (
    <Navbar expand="lg" className='navbar' >
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text className='logout-btn' style={{ color: 'white', fontSize: '1rem' }} onClick={() => logout()} ><BiLogOut className='logout-logo' />Logout</Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}

export { NavbarComp }