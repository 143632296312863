// import { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ModelComp = (props) => {
  const navigate = useNavigate()
  const { show } = props

  const logout = () => {
    localStorage.removeItem('accessTokenAdmin')
    navigate('/', { state: { logout: true } })
  }
  return (
    <Modal show={show} >
      <Modal.Header>
        <Modal.Title>Token Expired !</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your Session has Expired Please Login Again</Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> */}
        <Button className='primaty-btn' size='sm' variant="primary" onClick={logout}>
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModelComp }