
import React, { useEffect, useState } from 'react';
import { getallUsers } from '../../../apis/apis';
import '../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col } from 'react-bootstrap';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { BiExport } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { SpinnerComp } from '../../../pages/spinner';
import { ConfirmationModleComp } from '../../../pages/popup/confirmationbox'
import './user.css'
const ViewUserComp = () => {
  const [users, setUsers] = useState([])
  const [showLoader, setshowLoader] = useState(false)
  const [confirmation, setConfirmation] = useState([false, ''])
  const [reload, setReload] = useState('')
  const [currentpageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setSizeperPage] = useState(10)
  const navigate = useNavigate()
  useEffect(() => {
    setshowLoader(true)
    getallUsers().then((res) => {
      setUsers(res.data.response.result.data.users)
      setshowLoader(false)
    })
  }, [reload])

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const DeleteformaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <>
        <MdDelete className='delete-icon' onClick={() => { deletUser(row._id) }} />
      </>
    )
  }

  const deletUser = (id) => {

    setConfirmation([true, id])
  }

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPageNum(page)
      setSizeperPage(sizePerPage)
    },
    onPageChange: (page, sizePerPage) => {
      setCurrentPageNum(page)
      console.log(page)
      setSizeperPage(sizePerPage)

    }
  };
  const columns = [
    {
      dataField: 'sl.no',
      text: 'SN',
      formatter: (cell, row, rowIndex, page) => {
        const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
        return serialNum
      },
      // sort: true,
      headerStyle: {
        width: '30px'
      }
    },
    {
      dataField: 'firstName',
      text: 'FirstName',
      style: {
        fontSize: '12px'
      },
      headerStyle: {
        width: '100px'
      },
      sort: true,
    },
    {
      dataField: 'lastName',
      text: 'LastName',
      style: {
        fontSize: '12px'
      },
      headerStyle: {
        width: '100px'
      },
      sort: true,
    },

    {
      dataField: 'email',
      text: 'Email',
      style: {
        fontSize: '12px'
      },
      formatter: (value, row) => (
        <span className='small-text'>{value.split('@').join('@\n').split('.').join('.\n')}</span >
      ),
      headerStyle: {
        width: '220px'
      },
      sort: true,

    },
    {
      dataField: 'organization',
      text: 'Org',
      style: {
        fontSize: '12px'
      },
      headerStyle: {
        width: '150px'
      },
      sort: true,
    },
    {
      dataField: 'domain',
      text: 'Domain',
      style: {
        fontSize: '12px'
      },
      formatter: (value, row) => (
        <span className='small-text' >{value.join(' , ')}</span>
      ),
      sort: true,
    },

    {
      dataField: 'role',
      text: 'Role',
      style: {
        fontSize: '12px'
      },
      sort: true,
    },
    {
      dataField: 'areaOfExpertise',
      text: 'Expertise',
      style: {
        fontSize: '12px'
      },
      formatter: (value, row) => (
        <span className='small-text' >{value.join(' , ')}</span>
      ),
      sort: true,
    },
    {
      dataField: 'profileLink',
      text: 'Link',
      style: {
        fontSize: '12px'
      },
      formatter: (value, row) => (
        <span className='small-text'>{<a style={{ fontSize: '12px' }} href={value} rel="noreferrer" target="_blank" >{value ? value.split('.').join('.\n') : "No Link"}</a>}</span >
      ),
      sort: true,
    },
    {
      dataField: 'country',
      text: 'Country',
      style: {
        fontSize: '12px'
      },
      sort: true,
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: DeleteformaterHandler,
      style: {
        fontSize: '12px'
      },
      headerStyle: {
        width: "30px"
      }
    }
  ];



  return (
    <div>
      {confirmation[0] && <ConfirmationModleComp show={confirmation[0]} setReload={setReload} id={confirmation[1]} method={"user"} message={"Do You want to Delete this User"} onHide={() => setConfirmation([false, ''])} />}
      <SpinnerComp Show={showLoader} />
      <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate(-1)} />
      <span className='dashboard-title'>Users</span>
      <>
        <ToolkitProvider
          keyField="id"
          data={users}
          columns={columns}
          exportCSV
          search
        >
          {
            props => (
              <div>
                <Row  >
                  <Col>
                    <SearchBar {...props.searchProps} className="searchBar" style={{ marginBottom: '10px' }} />
                  </Col>
                  <Col className='export-class' >
                    <ExportCSVButton className="export-btn" {...props.csvProps}><BiExport className='export-icon' />Export CSV</ExportCSVButton>
                  </Col>
                </Row>
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  striped
                  hover
                  condensed
                  bootstrap4
                />
              </div>
            )
          }
        </ToolkitProvider>
      </>
    </div>
  )
}

export { ViewUserComp }