import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarComp } from '../sidebar';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { NavbarComp } from '../navbar';
import { useWindowDimensions } from '../../pages/helpers/getWindowsDimension';
import './dashboard.css'

const DashboardComp = () => {
    const [isActive, setActive] = useState()
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        width < 720 ? setActive(true) : setActive(false)
    }, [height, width])

    return (
        <>
            <Row>
                <NavbarComp />
            </Row>
            <Row>
                <Col>
                    <SidebarComp passChild={setActive} currentState={isActive} />
                    <div className={isActive ? 'dashboard-div-active' : 'dashboard-div'}>
                        <Outlet />
                    </div>
                </Col>
            </Row>
        </>

    )
}

export { DashboardComp }