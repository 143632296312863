import { useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/js/bootstrap.js";

import { SigninComp } from "./components/signin";
import { DashboardComp } from "./components/dashboard";
import { ManageComp } from "./components/manage";

import "./components/css/body.css";
import { ReportComp } from "./components/report";
import { HomeComp } from "./components/home";
import { ProfileComp } from "./components/profile";
import { ViewfilesComp } from "./components/manage/files";
import { ViewKeywordsComp } from "./components/manage/keywords";
import { UploadFileComp } from "./components/manage/files/upload";
import { ViewUserComp } from "./components/manage/user/user";
import { ViewCollectionComp } from "./components/manage/collections";
import { AddCollectionComp } from "./components/manage/collections/addcollections";
import { ViewIndividualComp } from "./components/manage/files/viewIndividual/viewIndividual";
import { QuestionsComp } from "./components/manage/questions";
import { ViewMessageComp } from "./components/manage/queries";
import { getallUsers } from "./apis/apis";

const App = () => {
  const { state } = useLocation();
  const [SignedIn, setSignedIn] = useState();
  useEffect(() => {
    if (!localStorage.getItem("accessTokenAdmin")) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  }, [state]);

  getallUsers().then(() => { }).catch((err) => { return <Navigate to={'/'} replace />; })

  const ProtectedRoute = ({ redirectPath = "/" }) => {
    const accessToken = localStorage.getItem("accessTokenAdmin");
    if (!accessToken) {
      return <Navigate to={redirectPath} replace />;
    } else {
      return <Outlet />;
    }
  };

  return (
    <Routes>
      <Route path="*" element={SignedIn ? <SigninComp /> : <DashboardComp />} />
      <Route path="/" element={SignedIn ? <SigninComp /> : <DashboardComp />} />
      <Route element={<ProtectedRoute />}>
        <Route exact={true} path="dashboard" element={<DashboardComp />}>
          <Route exact={true} path="homepage" element={<HomeComp />} />
          <Route exact={true} path="manage" element={<ManageComp />} />
          <Route exact={true} path="report" element={<ReportComp />} />
          <Route exact={true} path="profile" element={<ProfileComp />} />
          <Route exact={true} path="viewfiles" element={<ViewfilesComp />} />
          <Route exact={true} path="uploadFiles" element={<UploadFileComp />} />
          {/* users */}
          <Route exact={true} path="viewusers" element={<ViewUserComp />} />
          <Route
            exact={true}
            path="viewkeywords"
            element={<ViewKeywordsComp />}
          />
          <Route
            exact={true}
            path="viewcollections"
            element={<ViewCollectionComp />}
          />
          <Route
            exact={true}
            path="addCollection"
            element={<AddCollectionComp />}
          />
          <Route
            exact={true}
            path="viewIndividualFiles"
            element={<ViewIndividualComp />}
          />
          <Route
            exact={true}
            path="viewQuestions"
            element={<QuestionsComp />}
          />
          <Route
            exact={true}
            path="viewMessage"
            element={<ViewMessageComp />}
          />

        </Route>
      </Route>
    </Routes>
  );
};

export default App;
