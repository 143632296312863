import { useEffect, useState } from "react"
import { Button, Form, Row, Col, Badge } from 'react-bootstrap'
import { useLocation, useNavigate } from "react-router-dom"
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { addBundel, UpdateBundel, getBundle } from "../../../apis"
import { TableComp } from "../../../pages/helpers/table";
import { difference } from "../../../pages/helpers/diffrence"
import { MdCancel } from 'react-icons/md'
import { ClosableModelComp } from "../../../pages/popup/closableModle"
const AddCollectionComp = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [docBatch, setdocBatch] = useState([]);
  const [keywordBadge, setkeywordBadge] = useState([])
  const [obj1, setObj1] = useState()
  const [docIds, setdocIds] = useState([]);
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [modelmessage, setmodelmessage] = useState('')
  const [formData, setFormData] = useState(
    {
      bundleTitle: '',
      description: '',
      publishedBy: '',
      publishedDate: '',
      docIds: docIds,
      keywords: keywordBadge
    }
  )
  const { isNew } = state ? state : {};
  useEffect(() => {
    if (state) {
      const { id, isNew } = state
      if (!isNew) {

        getBundle(id).then((res) => {
          const data = res.data.response.result.data
          const docIds = data.docIds.map((docs) => docs._id)
          const predocBatch = data.docIds.map((docs) => docs.docTitle)
          const previousDetails = {
            bundleTitle: data.bundleTitle,
            description: data.description,
            publishedBy: data.publishedBy,
            publishedDate: data.publishedDate,
            docIds: docIds,
            keywords: data.keywords
          }
          setFormData(previousDetails)
          setObj1(previousDetails)
          setdocBatch(predocBatch)
          setdocIds(docIds)
          setkeywordBadge(data.keywords)
        })
      }
    }
    else {
      navigate('/dashboard/viewcollections')
    }

  }, [navigate, state])

  useEffect(() => {
    setFormData({ ...formData, docIds })
  }, [docIds])

  const filterBagde = (i) => {
    const newarray = docBatch.filter((item, index) => index !== i)
    setdocBatch(newarray)
    const newidArray = docIds.filter((item, index) => index !== i)
    setdocIds(newidArray)
  }

  const updateFormData = async (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    if (state.isNew) {
      addBundel(formData).then((res) => {
        if (res.status === 200) {
          setmodelmessage("Collection added Sucessfully")
          setupdatesuccess(true)
          setTimeout(() => {
            navigate('/dashboard/viewcollections')
          }, 1000)
        }
      })
    }
    else {
      const newObj = difference(obj1, formData)
      UpdateBundel(formData, state.id).then((res) => {
        if (res.status === 200) {
          setmodelmessage("Collection updated Sucessfully")
          setupdatesuccess(true)
          setTimeout(() => {
            navigate('/dashboard/viewcollections')
          }, 1000)
        }
      })
    }

  }

  const onKeyPressHandler = (e) => {
    e.preventDefault();
    handleKeywords(e);
  };

  const handleKeywords = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const array = [...keywordBadge, value];
      setkeywordBadge(array);
      setFormData({ ...formData, keywords: array });
      e.target.value = "";
    }
  };

  const filterKeywordBagde = (i) => {
    const newarray = keywordBadge.filter((item, index) => index !== i);
    setkeywordBadge(newarray);
  };
  return (
    <>
      {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={modelmessage} />}
      <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate('/dashboard/viewcollections')} />
      <span className="dashboard-title">{isNew ? "Add Collection" : "Edit Collection"}</span>

      <Form id="collection-form" onSubmit={(e) => handelSubmit(e)} >
        <Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Title <sup className="required-icon" >*</sup> </Form.Label>
            <Form.Control type="text"
              required
              placeholder="Enter Title"
              name='bundleTitle'
              value={formData.bundleTitle}
              onChange={(e) => updateFormData(e)}
              size="sm"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Description <sup className="required-icon" >*</sup> </Form.Label>
            <Form.Control type="text" as='textarea'
              required placeholder="Enter Description"
              name='description'
              value={formData.description}
              onChange={(e) => updateFormData(e)}
              size="sm"
            />
          </Form.Group>
          {/* *******Keywords******* */}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Keywords</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Keywords"
              // disabled={isUploaded}
              onKeyPress={(e) => e.key === "Enter" && onKeyPressHandler(e)}
              onBlur={(e) => handleKeywords(e)}
              size="sm"
            />
            {keywordBadge.map((badge, index) => {
              return (
                <>
                  <Badge pill className="collection-badge" bg='' size="sm" >
                    {badge}
                    {"  "}
                    <MdCancel onClick={() => filterKeywordBagde(index)} />
                  </Badge>{" "}
                </>
              );
            })}
          </Form.Group>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Published Date <sup className="required-icon" >*</sup> </Form.Label>
              <Form.Control type="date" placeholder="Date"
                required
                name="publishedDate"
                value={formData.publishedDate ? formData.publishedDate.substring(0, 10) : new Date().toISOString().substring(0, 10)}
                defaultValue={new Date().toISOString().substring(0, 10)}
                onChange={(e) => updateFormData(e)}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Published By <sup className="required-icon" >*</sup> </Form.Label>
              <Form.Control type="text" placeholder="Published By"
                required
                name="publishedBy"
                value={formData.publishedBy}
                onChange={(e) => updateFormData(e)}
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>
        <div>
          <Form.Label>Selected Files</Form.Label><br />
          {
            docBatch.map((badge, index) => {
              return (
                <>
                  <Badge className="collection-badge" bg='' pill size="sm"  >{badge}{'  '}<MdCancel onClick={() => filterBagde(index)} /></Badge>{' '}
                </>
              )

            })
          }
        </div>
        <br />
        <TableComp className="collection-table" setdocBatch={setdocBatch} docBatch={docBatch} setdocIds={setdocIds} docIds={docIds} />
        <div className="control-buttons">
          <Button form="collection-form" type="submit" disabled={!docBatch.length > 0} className="primary-btn" size="sm" >Submit</Button>
          <Button onClick={() => { navigate(-1) }} className="primary-btn" size="sm" >Cancel</Button>
        </div>
      </Form>
      { }


    </>
  )
}

export { AddCollectionComp }