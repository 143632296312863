import React from "react"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './signin.css'
import { signInwithEmailAndPAssword } from "../../apis";

const SigninComp = () => {
  const navigator = useNavigate();
  const [errmsg, setErrMesg] = useState("");
  const [userData, setuserData] = useState({
    "email": '',
    "password": ""
  })
  const updateFormData = async (e) => {
    const { name, value } = e.target
    setuserData({ ...userData, [name]: value })
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.email === "") {
      setErrMesg("Name Cannot be empty!");
      return;
    }
    if (userData.password === "") {
      setErrMesg("Password Cannot be empty!");
      return;
    }

    signInwithEmailAndPAssword(userData)
      .then((res) => {
        // fix this respone
        if (res.data.response.result.message === "Login successful") {
          localStorage.setItem(
            "accessTokenAdmin",
            res.data.response.result.accessToken
          );
          navigator('/dashboard/homepage')
        }
      })
      .catch((error) => {
        setErrMesg(error.response.data.response.params.errmsg);
      });
  };

  errmsg && setTimeout(() => {
    setErrMesg()
  }, 2000)
  return (
    <>
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={(e) => { handleSubmit(e) }} >
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                //   isRequired={true}
                required
                name="email"
                title="Email"
                value={userData.email}
                onChange={updateFormData}
                errorMess={errmsg}
                customErrMessClass="text-danger"
              />

            </div>

            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                required
                name="password"
                title="Password"
                value={userData.password}
                onChange={updateFormData}
              />
            </div>
            {errmsg && <div className="error-msg"  >
              {errmsg}
            </div>}
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn primary-btn btn-primary">
                Login
              </button>
            </div>
            <p className="forgot-password text-right mt-2">

            </p>
          </div>
        </form>
      </div>
    </>
  )
}

export { SigninComp }