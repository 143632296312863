import React, { useEffect, useState } from 'react';
// import { Container , Button, Table} from 'react-bootstrap';
import { getallKeywords, updateKeywords, addKeywords } from '../../../apis';
import '../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom'
import { FaEdit } from "react-icons/fa";
import Switch from "react-switch";
import './keywords.css'
import { SpinnerComp } from '../../../pages/spinner';
import { ClosableModelComp } from '../../../pages/popup/closableModle';

const { SearchBar } = Search;
const ViewKeywordsComp = () => {
  const [checked, setchecked] = useState([]);
  const [reloadPage, setreloadPage] = useState()
  const [modalShow, setModalShow] = useState(false);
  const [inputValue, setInputValue] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [currentpageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setSizeperPage] = useState(10)

  // const [mystate , setmystate] = useState(false)
  const handleChange = (id, isActive) => {
    const checkedIds = [...checked]
    const index = checkedIds.indexOf(id)
    if (index !== -1) {
      checkedIds.splice(index, 1)
    } else {
      checkedIds.push(id)
    }
    setchecked(checkedIds);

    const toggle = !isActive
    updateKeywords({ isActive: toggle }, id).then((res) => {

      if (res.status === 200) {
        setupdatesuccess(true)
        setreloadPage(Math.random())
      }
    })
  };

  const navigate = useNavigate()

  const [keywords, setKeywords] = useState([])
  useEffect(() => {
    setshowLoader(true)
    getallKeywords().then((res) => {
      const keywordsData = res.data.response.result.data.result
      setKeywords(keywordsData ? keywordsData : [])
      setshowLoader(false)
    })
  }, [reloadPage])


  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const ids = keywords.map((doc) => {
      if (doc.isActive) {
        return doc._id
      }
    })
    const finalIds = ids.filter((id) => id !== undefined)
    setchecked(finalIds)
  }, [keywords])




  const ToggleformaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <>

        <Switch
          key={row._id}
          onChange={() => handleChange(row._id, row.isActive)}
          checked={extraData.includes(row._id)}
          className="react-switch"
          width={40}
          height={20}
          onColor={'#925eb0'}
          activeBoxShadow={'0 0 2px 3px #9204b0'}
        />
      </>
    )
  }

  const formaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <>
        {/* <FaEdit className='edit-icon' onClick={() => navigate('/dashboard/editKeywords', { state: { id: row._id, title: row.title } })} /> */}
        <FaEdit className='edit-icon' onClick={() => callModel(row)} />
      </>
    )
  }


  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPageNum(page)
      setSizeperPage(sizePerPage)
    },
    onPageChange: (page, sizePerPage) => {
      setCurrentPageNum(page)
      console.log(page)
      setSizeperPage(sizePerPage)

    }
  };


  let columns = [
    {
      dataField: 'sl.no',
      text: 'SN.',
      formatter: (cell, row, rowIndex, page) => {
        const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
        return serialNum
      },
      // sort: true,
      headerStyle: {
        width: '40px'
      }
    },
    {
      dataField: 'title',
      text: 'Title',
      style: {
        fontSize: '12px'
      },
      sort: true
    }, {
      dataField: '',
      text: 'IsActive',
      formatter: ToggleformaterHandler,
      formatExtraData: checked,
    },
    {
      dataField: '',
      text: ' Actions',
      formatter: formaterHandler,
    }
  ];

  const callModel = (row) => {
    setInputValue(row)
    setModalShow(true)
  }

  const addcallModel = () => {
    setInputValue({});
    setModalShow(true);
  }

  return (
    <>
      {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={"Keywords updated Successfully"} />}
      <SpinnerComp Show={showLoader} />
      <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate('/dashboard/manage')} />
      <span className='dashboard-title'>Keywords</span>
      <ToolkitProvider
        keyField="id"
        data={keywords}
        columns={columns}
        columnToggle
        search
      >
        {
          props => (
            <div>
              <Row  >
                <Col>
                  <SearchBar {...props.searchProps} className="searchBar" />
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }} >
                  <Button variant="primary" className="primary-btn" size="sm" onClick={addcallModel}>+ Add Keyword</Button>
                </Col>
              </Row>

              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                bootstrap4
                condensed
                size="sm"
              />

            </div>
          )
        }
      </ToolkitProvider>

      <AddKeywordsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setreloadPage={setreloadPage}
        InputValue={inputValue}
      />
    </>
  )
};

const AddKeywordsModal = (props) => {
  const { setreloadPage, InputValue } = props

  const isempty = !Object.values(InputValue).some(x => x !== null && x !== '');

  const [newKeywords, setNewKeywords] = useState('')
  const [success, setsuccess] = useState(false)
  const [disablebtn, setdisablebtn] = useState(true);
  useEffect(() => {
    if (newKeywords === "") {
      setdisablebtn(true)
    }
    else {
      setdisablebtn(false)
    }
  }, [newKeywords])

  useEffect(() => {
    setNewKeywords(InputValue.title)
  }, [InputValue])

  const submitKeywords = () => {
    if (isempty) {
      addKeywords(newKeywords).then((res) => {
        if (res.status === 200) {
          setsuccess(true)
          setreloadPage(Math.random())
          setTimeout(() => {
            setsuccess(false)
            props.onHide()
          }, 1000);
        }
      })
    }
    else {
      const id = InputValue._id
      const data = { title: newKeywords }
      updateKeywords(data, id).then((res) => {
        if (res.status === 200) {
          setsuccess(true);
          setreloadPage(Math.random());
          setTimeout(() => {
            setsuccess(false);
            props.onHide();
          }, 1000);
        }
      })
    }

  }

  const Successmsg = () => {
    return (
      <span className='success' >Keyword {isempty ? 'added' : 'updated'} Successfuly</span>
    )
  }

  const handelCancel = () => {
    setNewKeywords(InputValue.question)
    props.onHide()
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isempty ? "Add Keywords" : "Edit Keywords"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Keyword <sup className="required-icon" >*</sup> </Form.Label>
            <Form.Control type="text"
              required={true}
              placeholder="Enter Keywords"
              value={isempty ? InputValue.title : newKeywords}
              onChange={(e) => setNewKeywords(e.target.value)}
              size="sm"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {success ? Successmsg() : ''}
        <Button onClick={submitKeywords} className="primary-btn" disabled={disablebtn} size="sm" >{isempty ? 'Add' : 'Edit'}</Button>
        <Button onClick={handelCancel} className="primary-btn" size="sm" >Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ViewKeywordsComp }