
import React, { useEffect, useState } from 'react';
import { getAllContactMessages } from '../../../apis/apis';
import '../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { SpinnerComp } from '../../../pages/spinner';
import { CenterModelComp } from '../../../pages/popup/centermodel';
import { MdDelete } from 'react-icons/md'
import { ConfirmationModleComp } from '../../../pages/popup/confirmationbox'
const ViewMessageComp = () => {
    const [Messages, setMessages] = useState([])
    const [reload, setReload] = useState('')
    const [showLoader, setshowLoader] = useState(false)
    const [modelShow, setModalShow] = useState(false)
    const [userMessage, setuseMessage] = useState('')
    const [confirmation, setConfirmation] = useState([false, ''])
    const [currentpageNum, setCurrentPageNum] = useState(1)
    const [pageSize, setSizeperPage] = useState(10)
    const navigate = useNavigate()
    useEffect(() => {
        setshowLoader(true)
        getAllContactMessages().then((res) => {
            setMessages(res.data.response.result.data)
            setshowLoader(false)
        })
    }, [reload])

    const { SearchBar } = Search;
    const DeleteformaterHandler = (cellContent, row, rowIndex, extraData) => {
        return (
            <>
                <MdDelete className='delete-icon' onClick={() => { deletUser(row._id) }} />
            </>
        )
    }
    const deletUser = (id) => {

        setConfirmation([true, id])
    }


    const options = {
        onSizePerPageChange: (sizePerPage, page) => {
            setCurrentPageNum(page)
            setSizeperPage(sizePerPage)
        },
        onPageChange: (page, sizePerPage) => {
            setCurrentPageNum(page)
            console.log(page)
            setSizeperPage(sizePerPage)

        }
    };

    const columns = [
        {
            dataField: 'sl.no',
            text: 'SN',
            formatter: (cell, row, rowIndex, page) => {
                const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
                return serialNum
            },
            // sort: true,
            headerStyle: {
                width: '40px'
            }
        },
        {
            dataField: 'name',
            text: 'Name',
            style: {
                fontSize: '12px'
            },
            sort: true
        },
        {
            dataField: 'email',
            text: 'Email',
            style: {
                fontSize: '12px'
            },
            sort: true
        },
        {
            dataField: 'message',
            text: 'Message',
            style: {
                fontSize: '12px'
            },
            formatter: (value, row) => {
                if (value.length > 80) {
                    return (
                        value.slice(0, 80) + '.....'
                    )
                }
                else {
                    return (
                        value
                    )
                }
            },
            events: {
                onClick: ((e, column, columnIndex, row, rowIndex) => { showMessage(row) })
            },
            sort: true

        },
        {
            dataField: 'createdAt',
            text: 'Time',
            style: {
                fontSize: '12px'
            },
            formatter: (value, row) => (
                <> <span style={{ fontSize: '13px' }}>{new Date(value).toLocaleDateString()}</span>
                    <span style={{ fontSize: '13px', marginLeft: '0.5rem' }}> {new Date(value).toLocaleTimeString('en-US')}</span>
                </>
            ),
            sort: true
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: '',
            formatter: DeleteformaterHandler,
            style: {
                fontSize: '12px'
            },
            headerStyle: {
                width: "30px"
            }
        }
    ];

    const showMessage = (message) => {
        setuseMessage(message)
        setModalShow(true)
    }

    return (
        <div>
            {confirmation[0] && <ConfirmationModleComp show={confirmation[0]} setReload={setReload} id={confirmation[1]} method={"message"} message={"Do you want to Delete this Message"} onHide={() => setConfirmation([false, ''])} />}
            <CenterModelComp show={modelShow} message={userMessage} onHide={() => setModalShow(false)} />
            <SpinnerComp Show={showLoader} />
            <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate(-1)} />
            <span className='dashboard-title'>Messages</span>
            <>
                <ToolkitProvider
                    keyField="id"
                    data={Messages}
                    columns={columns}


                    search
                >
                    {
                        props => (
                            <div>
                                <Row  >
                                    <Col>
                                        <SearchBar {...props.searchProps} className="searchBar" style={{ marginBottom: '10px' }} />
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory(options)}
                                    striped
                                    hover
                                    condensed
                                    bootstrap4

                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </>
        </div>
    )
}

export { ViewMessageComp }