
import { ManageComp } from "../manage"
const HomeComp = () => {
  return (
    <>
      <div className="dashboard-title">Coming Soon....</div>
    </>
  )
}

export { HomeComp }