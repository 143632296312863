import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { getallDocs, getallUsers, getallKeywords, getallCollections, getAllQuestions, getAllContactMessages } from '../../apis/apis';
import { BsFillFolderFill, BsFillChatRightFill } from 'react-icons/bs'
import { VscSymbolKeyword } from 'react-icons/vsc'
import { FaUsers, FaQuestion } from 'react-icons/fa'
import { IoDocuments } from "react-icons/io5"
import { AiFillSetting } from 'react-icons/ai'
import './manage.css'
import { SmallSpinnerComp } from '../../pages/spinner';
import { ModelComp } from '../../pages/popup';
const ManageComp = props => {

    const [filecount, setFileCount] = useState()
    const [usercount, setUserCount] = useState()
    const [keywordcount, setKeywordsCount] = useState()
    const [collectioncount, setcollectioncount] = useState()
    const [questionscount, setQuestionscount] = useState()
    const [messagecount, setmessagecount] = useState()
    const [isTokenExpired, setIsTokenExpired] = useState(false)
    const [showSmallSpinnerFiles, setshowSmallSpinnerFiles] = useState(false)
    const [showSmallSpinnerKeywords, setshowSmallSpinnerKeywords] = useState(false)
    const [showSmallSpinnerUsers, setshowSmallSpinnerUsers] = useState(false)
    const [showSmallSpinnerBundle, setshowSmallSpinnerBundle] = useState(false)
    const [showSmallSpinnerQuestions, setshowSmallSpinnerQuestions] = useState(false)
    const [showSmallSpinnerMessages, setshowSmallSpinnerMessages] = useState(false)



    useEffect(() => {

        setshowSmallSpinnerFiles(true)
        getallDocs().then((res) => {
            if (res.status === 200) {
                setFileCount(res.data.response.result.data.counts)
                setshowSmallSpinnerFiles(false)
            }
            if (res.status === 401) {
                setIsTokenExpired(true)
            }

        }).catch((err) => {
            setIsTokenExpired(true)
        })

        setshowSmallSpinnerUsers(true)
        getallUsers().then((res) => {
            if (res.status === 200) {
                setUserCount(res.data.response.result.data.counts)
                setshowSmallSpinnerUsers(false)
            }
            if (res.status === 401) {
                setIsTokenExpired(true)
            }
        }).catch((err) => {
            setIsTokenExpired(true)
        })

        setshowSmallSpinnerKeywords(true)
        getallKeywords().then((res) => {
            if (res.status === 200) {
                setKeywordsCount(res.data.response.result.count)
                setshowSmallSpinnerKeywords(false)
            }
            if (res.status === 401) {
                setIsTokenExpired(true)
            }
        }).catch((err) => {
            setIsTokenExpired(true)
        })

        setshowSmallSpinnerBundle(true)
        getallCollections().then((res) => {
            if (res.status === 200) {
                setcollectioncount(res.data.response.result.data.counts)
                setshowSmallSpinnerBundle(false)
            }
            if (res.status === 401) {
                setIsTokenExpired(true)
            }
        }).catch((err) => {
            setIsTokenExpired(true)
        })
        setshowSmallSpinnerQuestions(true)
        getAllQuestions().then((res) => {
            if (res.status === 200) {
                setQuestionscount(res.data.response.result.count)
                setshowSmallSpinnerBundle(false)
                setshowSmallSpinnerQuestions(false)
            }
        }).catch((err) => {
            setIsTokenExpired(true)
        })
        setshowSmallSpinnerMessages(true)
        getAllContactMessages().then((res) => {
            if (res.status === 200) {
                setmessagecount(res.data.response.result.count)
                setshowSmallSpinnerMessages(false)
            }
            if (res.status === 401) {
                setIsTokenExpired(true)
            }
        }).catch((err) => {
            setIsTokenExpired(true)
        })

    }, [])
    const navigate = useNavigate()
    return (
        <>
            {isTokenExpired && <ModelComp show={isTokenExpired} />}
            <>
                <div className='dashboard-title' >Manage</div>
                <Row className='card-row'>
                    {/* Files */}
                    <Col>
                        <Card >
                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerFiles} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><IoDocuments className='react-icon' /></span>Documents</h2></Card.Title>
                                <Card.Text>
                                    Total Files : {filecount}
                                </Card.Text>
                                <Button className='card-btn' variant="primary" onClick={() => navigate('/dashboard/viewfiles')}>
                                    <AiFillSetting className='setting-icon' /> Manage
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        {/* Keywords */}
                        <Card className='card'>
                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerKeywords} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><VscSymbolKeyword className='react-icon' /></span>Keywords</h2></Card.Title>
                                <Card.Text>
                                    Total Keywords : {keywordcount}
                                </Card.Text>
                                <Button className='card-btn' variant="primary" onClick={() => navigate('/dashboard/viewkeywords')}>
                                    <AiFillSetting className='setting-icon' /> Manage
                                </Button>
                            </Card.Body>
                        </Card>

                    </Col>

                    <Col>

                        {/* Users */}
                        <Card className='card'>
                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerUsers} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><FaUsers className='react-icon' /></span>Users</h2></Card.Title>
                                <Card.Text>
                                    Total users : {usercount}
                                </Card.Text>
                                <Button className='card-btn' variant="primary" onClick={() => navigate('/dashboard/viewusers')}>
                                    View
                                </Button>
                            </Card.Body>
                        </Card>

                    </Col>

                </Row>

                <Row className='card-row' >
                    <Col>

                        {/* QA */}
                        <Card className='card'>
                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerQuestions} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><FaQuestion className='react-icon' /></span>Questions</h2></Card.Title>
                                <Card.Text>
                                    Questions {questionscount}
                                </Card.Text>
                                <Button className='card-btn' variant="primary" onClick={() => navigate('/dashboard/viewQuestions')}>
                                    <AiFillSetting className='setting-icon' /> Manage
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='card'>
                            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerBundle} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><BsFillFolderFill className='react-icon' /></span>Collections</h2></Card.Title>
                                <Card.Text>
                                    Total Collections: {collectioncount}
                                </Card.Text>
                                <Button variant="primary" className='card-btn' onClick={() => navigate('/dashboard/viewcollections')}>
                                    <AiFillSetting className='setting-icon' /> Manage
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        {/* Customer Mesages */}
                        <Card className='card'>

                            <span className='small-spinner' > <SmallSpinnerComp Show={showSmallSpinnerMessages} /></span>
                            <Card.Body>
                                <Card.Title><h2><span className='icon-spam' ><BsFillChatRightFill className='react-icon' /></span>Queries</h2></Card.Title>
                                <Card.Text>
                                    Messages : {messagecount}
                                </Card.Text>
                                <Button className='card-btn' variant="primary" onClick={() => navigate('/dashboard/viewMessage')}>
                                    View
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>





                </Row>





                {/* Collections  */}




            </>
        </>
    )
}
export { ManageComp }