import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Badge,
  ProgressBar,
  InputGroup
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { MdCancel, MdOutlineCancel } from "react-icons/md";

import "./upload.css";
import {
  getPresingedUrl,
  addContent,
  getContent,
  updateContent,
} from "../../../../apis";
import { SpinnerComp } from "../../../../pages/spinner";
import { difference } from "../../../../pages/helpers/diffrence";
import { getHeader } from "../../../../pages/helpers/getHeaders";
import { ClosableModelComp } from '../../../../pages/popup/closableModle';
import { ReplaceModalComp } from "../../../../pages/popup/replaceModal";
import axios, { CancelToken, isCancel } from "axios";
const UploadFileComp = (props) => {
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [isFileUploaded, setisFileUploaded] = useState(false);
  const [hasReplace, sethasReplace] = useState(false);
  const [progress, setProgress] = useState();
  const [transcriptProgress, setTranscriptProgress] = useState();
  const [downloadUrl, setDownloadUrl] = useState();
  const [viewType, setviewType] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, isNewfile } = state ? state : {};
  const inputRef = useRef(null);
  const inputTransRef = useRef(null);
  const [reload, setReload] = useState('')

  useEffect(() => {
    if (state) {
      const { id, isNewfile } = state;
      if (!isNewfile) {
        setisUploaded(false);
        //geting individual content
        getContent(id).then((res) => {
          const Doctype = res.data.response.result.docType;
          if (Doctype === ".mp4") {
            setisMp4(true);
            setaccept("video/mp4");
            setisisRequired(false);
          }
          if (Doctype === ".pdf" || Doctype === ".xlsx" || Doctype === ".xlx") {
            setisPdf(true);
            setaccept("application/pdf , .doc , .csv , .txt");
          }
          if (Doctype === "link") {
            setisLink(true);
          }

          setFormData(res.data.response.result.data);
          setobj1(res.data.response.result.data);
          setBadges(res.data.response.result.data.keywords);
          setDownloadUrl(res.data.response.result.downloadUrl);
        });
      } else {
        // setisMp4(true)
        setisPdf(true);
        setisLink(true);
      }
    } else {
      navigate("/dashboard/viewfiles");
    }
  }, [navigate, state, reload]);
  const [obj1, setobj1] = useState();
  const [accept, setaccept] = useState(
    "application/pdf , .doc , .csv , .txt ,.xls ,.xlsx ,.pptx,.mp4,.mov,.mp3"
  );
  const [modelmessage, setmodelmessage] = useState('')
  const [isMp4, setisMp4] = useState(false);
  const [isPdf, setisPdf] = useState(false);
  const [isLink, setisLink] = useState(false);
  const [isRequired, setisisRequired] = useState(true);
  const [hasOtherLicence, sethasOtherLicence] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [isAttachementFilled, setisAttachementFilled] = useState(false);
  const [Transfilepath, setTransfilePath] = useState("");
  const [scriptFilename, setScriptfileName] = useState("");
  const [isBrowsefileFilled, setisBrowsefileFilled] = useState(false);
  const [isUploaded, setisUploaded] = useState(true);
  const [uploadUrl, setUploadUrl] = useState("");
  const [TransUrl, setTransUploadUrl] = useState("");
  const [filePath, setfilePath] = useState("");
  const [fileName, setfileName] = useState("");
  const [badges, setBadges] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [licenceArray, setlicenceArray] = useState([]);
  const [Otherlicence, setOtherLicence] = useState("");
  const [fileExtension, setfileExtension] = useState();
  const [invalidExtension, setInvalidExtension] = useState(false)
  const [TranscriptFileExt, setTranscriptFileExt] = useState();
  const cancelFileUpload = useRef(null);
  const [formData, setFormData] = useState({
    fileName: fileName,
    docTitle: "",
    docDesc: "",
    attachment: attachment,
    attribution: "",
    createdBy: "",
    licence: "CC-BY-SA 4.0",
    keywords: badges,
    scriptFileName: scriptFilename,
    publishedBy: "",
    type: "",
    catalog: "Experiences",
    publishedDate: new Date().toISOString().substring(0, 10),
  });

  const updateFormData = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getUploadUrl = async (e) => {
    if (e.target.value !== "") {
      setisBrowsefileFilled(true);
      const fileName = e.target.files[0].name;
      const fileExt = fileName.split(".").pop();
      setfileExtension(fileExt);

      fileExt === "mp4" || fileExt === "mov" ? setviewType("Watch") : fileExt === "mp3" ? setviewType("Listen") : setviewType("Read");
      // fileExt ==='mp4' ? setFormData({...formData , type:'watch'}) : setFormData({...formData , type:'read'})
      setfilePath(e.target.files[0]);
      setisMp4(fileExt === "mp4" || fileExt === "mov" || fileExt === "mp3");
      const istranscript = false
      getPresingedUrl(fileName, istranscript).then((res) => {

        if (res.status === 200) {

          setisFileUploaded(false);

          setUploadUrl(res.data.response.result.url);
          setfileName(res.data.response.result.docName);
          setInvalidExtension(false)
          //  fileExt ==='mp4' ? setFormData({...formData , type:'watch'}) : setFormData({...formData , type:'read'})
          setFormData({
            ...formData,
            fileName: res.data.response.result.docName,
            type: fileExt === "mp4" || fileExt === "mov" ? "Watch" : fileExt === "mp3" ? "Listen" : "Read",
          });
        }
      }).catch((err => {
        setInvalidExtension(true)
        setmodelmessage("Cannot Upload this type of Files")
        setupdatesuccess(true)
        // alert("Cannot Upload this type of Files")
      }));
    } else {
      setisBrowsefileFilled(false);
    }
  };


  const getTranscriptUrl = async (e) => {
    if (e.target.value !== "") {
      setisBrowsefileFilled(true);
      const fileName = e.target.files[0].name;
      setTranscriptFileExt(fileName.split(".").pop());
      setTransfilePath(e.target.files[0]);
      const istranscript = true
      getPresingedUrl(fileName, istranscript).then((res) => {
        if (res.status === 200) {
          setTransUploadUrl(res.data.response.result.url);
          setScriptfileName(res.data.response.result.docName);
          setInvalidExtension(false)
          setFormData({
            ...formData,
            scriptFileName: res.data.response.result.docName,
          });
        }

      }
      ).catch((err) => {
        setInvalidExtension(true)
        setmodelmessage("Cannot Upload this type of Files")
        setupdatesuccess(true)
      })
    } else {
      setisBrowsefileFilled(false);
    }
  };

  const handelUpload = async (e) => {
    e.preventDefault();
    //main file
    const contenType = await getHeader(fileExtension);
    const options = {
      headers: contenType,
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    await axios
      .put(uploadUrl, filePath, options)
      .then((res) => {
        if (res.status === 200) {
          //uploading transcript fle
          const contenType = getHeader(TranscriptFileExt);
          const options = {
            headers: contenType,
            onUploadProgress: (data) => {
              setTranscriptProgress(Math.round((100 * data.loaded) / data.total));
            },
            cancelToken: new CancelToken(
              (cancel) => (cancelFileUpload.current = cancel)
            ),
          }
          if (scriptFilename !== "") {
            axios.put(TransUrl, Transfilepath, options).then((res) => {
              if (res.status === 200) {
                setisUploaded(false);
                setProgress();
                setTranscriptProgress()
                setisFileUploaded(true);
                setmodelmessage("Document Uploaded With Transcript File Successfully")
                setupdatesuccess(true)
              }
            });
          } else {
            setisUploaded(false);
            setProgress();
            setTranscriptProgress()
            setisFileUploaded(true);
            setmodelmessage("Document Uploaded  Successfully")
            setupdatesuccess(true)
          }
        }
      })
      .catch((err) => {
        if (isCancel(err)) {
          setProgress();
          setTranscriptProgress()
        }
      });
  };

  const CancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("user has canceled the file upload");
    }
  };

  const renderMp4file = () => {
    return (
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Select Transcript File </Form.Label>
        <InputGroup size="sm" >
          <Form.Control
            ref={inputTransRef}
            name="file"
            type="file"
            size="sm"
            accept=".doc,.docx,application/pdf"
            onChange={(e) => getTranscriptUrl(e)}
            disabled={isAttachementFilled || isFileUploaded || progress || !isBrowsefileFilled}
          />
          {isBrowsefileFilled && <InputGroup.Text onClick={!isFileUploaded && resetTranscriptFileInput} size="sm" id="basic-addon1"><MdOutlineCancel /></InputGroup.Text>}
        </InputGroup>
        <span>{formData.scriptFileName}</span>
        <div>
          {transcriptProgress && (
            <ProgressBar now={transcriptProgress} label={`${progress}%`} />
          )}
        </div>
      </Form.Group>
    );
  };

  const ShowDownloadBtn = () => {
    return (
      <Form.Group
        style={{ paddingTop: "30px" }}
        controlId="formFile"
        className="mb-3"
      >
        <Button variant="primary" href={downloadUrl} target="_blank">
          Download
        </Button>
      </Form.Group>
    );
  };

  const changeFormData = () => {
    if (Otherlicence !== "" && formData.licence === "Others") {
      setFormData({ ...formData, licence: Otherlicence });
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    if (state.isNewfile) {
      await addContent(formData).then((res) => {
        if (res.status === 200) {
          setshowLoader(false);
          setmodelmessage("Content added Successfully")
          setupdatesuccess(true)
          setTimeout(() => {
            navigate("/dashboard/viewfiles");
          }, 1000)
        }
      });
    } else {
      const newObj = difference(obj1, formData);
      await updateContent(newObj, state.id).then((res) => {
        if (res.status === 200) {
          setshowLoader(false);
          setmodelmessage("Document updated Successfuly")
          setupdatesuccess(true)
          setTimeout(() => {
            navigate("/dashboard/viewfiles");
          }, 1000)
        }
      });
    }
  };

  const handleKeywords = (e) => {
    const { value } = e.target;
    if (value !== "") {
      const array = [...badges, value];
      setBadges(array);
      setFormData({ ...formData, keywords: array });
      e.target.value = "";
    }
  };

  const handelAttachment = (e) => {
    const { value } = e.target;
    setAttachment(value);
    setFormData({ ...formData, attachment: value });
    if (value !== "") {
      setisAttachementFilled(true)
        (state.isNewfile ? setisUploaded(false) : "");
    } else {
      setisAttachementFilled(false)
        (state.isNewfile ? setisUploaded(true) : "");
    }
  };

  const onKeyPressHandler = (e) => {
    e.preventDefault();
    handleKeywords(e);
  };

  const filterBagde = (i) => {
    const newarray = badges.filter((item, index) => index !== i);
    setBadges(newarray);
  };

  useEffect(() => {
    if (
      formData.catalog === "Experiences" ||
      formData.catalog === "Method"
    ) {
      setlicenceArray([
        "CC-BY-SA 4.0",
        "CC-O",
        "CC-BY 4.0",
        "CC-BY-ND 4.0",
        "CC-BY-NC 4.0",
        "CC-BY-NC-SA 4.0",
        "CC-BY-NC-ND 4.0",
        "Others",
      ]);
    } else if (formData.catalog === "Technology") {
      setlicenceArray(["MIT", "Apache", "Others"]);
    }
  }, [formData.catalog]);

  const addOtherLicence = (e) => {
    setOtherLicence(e.target.value);
  };
  const renderOtherLicence = () => {
    return (
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Enter Licence</Form.Label>
        <Form.Control
          type="text"
          size="sm"
          placeholder="Licence"
          name="licence"
          onChange={(e) => addOtherLicence(e)}
          disabled={isUploaded}
        />
      </Form.Group>
    );
  };

  const UpdateLicence = (e) => {
    updateFormData(e);
    if (e.target.value === "Others") {
      sethasOtherLicence(true);
    } else {
      sethasOtherLicence(false);
    }
  };

  const resetFileInput = () => {


    inputRef.current.value = null;
    // e.target.value = ""
    setisBrowsefileFilled(false);
    setFormData({ ...formData, fileName: '' });


  }

  const resetTranscriptFileInput = () => {
    inputTransRef.current.value = null;
    setFormData({ ...formData, scriptFileName: '' });

  }

  const renderAttachment = () => {
    return (
      <>
        <Row>
          <Col md={10}>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              type="hidden"
            >
              <Form.Label>
                Link <sup className="required-icon">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                //  required
                placeholder="Paste Attachment"
                name="docTitle"
                size="sm"
                value={formData.attachment}
                onChange={(e) => handelAttachment(e)}
                disabled={isBrowsefileFilled}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                Select Type <sup className="required-icon">*</sup>
              </Form.Label>
              <Form.Select
                required
                name="type"
                disabled={isUploaded || isFileUploaded}
                value={formData.type}
                onChange={(e) => updateFormData(e)}
                aria-label="Default select example"
                size="sm"
              >
                <option value="Read">Read</option>
                <option value="Watch">Watch</option>
                <option value="Listen"> Listen </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  const renderBrowseFile = () => {
    return (
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>
          Select File {isRequired ? <sup className="required-icon">*</sup> : ""}
        </Form.Label>
        <InputGroup size="sm" >
          <Form.Control
            ref={inputRef}
            // className="browse-file"
            required={isRequired}
            name="file"
            type="file"
            accept={accept}
            // value={formData.fileName}
            onChange={(e) => getUploadUrl(e)}
            disabled={isAttachementFilled}
            size="sm"
            id="basic-addon1"

          />
          {isBrowsefileFilled && <InputGroup.Text onClick={!isFileUploaded && resetFileInput} size="sm" id="basic-addon1"><MdOutlineCancel /></InputGroup.Text>}
        </InputGroup>
        <span>{formData.fileName}</span>
        <div>
          {progress && <ProgressBar now={progress} label={`${progress}%`} />}
        </div>
      </Form.Group>
    );
  };

  const replaceModal = () => {
    sethasReplace(true)
  }

  return (
    <>
      {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={modelmessage} />}
      <SpinnerComp Show={showLoader} />
      <BsFillArrowLeftCircleFill
        className="back-button"
        onClick={() => navigate("/dashboard/viewfiles")}
      />
      <span className='dashboard-title'>
        {isNewfile && isNewfile ? "Add Document" : "Edit Document"}
      </span>

      {isNewfile &&
        <Form onSubmit={(e) => handelUpload(e)}>
          {/* Browse file */}
          <Row>
            <Col md={10}>{isPdf || isMp4 ? renderBrowseFile() : ""}</Col>
            <Col md={2}>
              {!isNewfile && !isLink ? ShowDownloadBtn() : ""}
            </Col>
          </Row>

          {isMp4 ? renderMp4file() : ""}
          {/* Attachment  */}

          {/* {isLink ? renderAttachment() : ""} */}

          <Form.Group controlId="formFile" className="mb-3">
            <Button
              variant="primary"
              type="submit"
              className="primary-btn"
              disabled={isAttachementFilled || isFileUploaded || progress || invalidExtension}
              size="sm"
            >
              Upload File
            </Button>
            <Button
              className="primary-btn"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                CancelUpload();
              }}
              disabled={isAttachementFilled || isFileUploaded}
              size="sm"
            >
              Cancel
            </Button>
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3"></Form.Group>
        </Form>}
      {isLink ? renderAttachment() : ""}
      <Form id="document-form" onSubmit={(e) => handelSubmit(e)}>
        <Row>
          {/* ********Title********** */}
          <Col md={8}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Title <sup className="required-icon">*</sup>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Title"
                name="docTitle"
                value={formData.docTitle}
                onChange={(e) => updateFormData(e)}
                disabled={isUploaded}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            {/* *******Method******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Catalogue</Form.Label>
              <Form.Select
                required
                name="catalog"
                size="sm"
                disabled={isUploaded}
                value={formData.catalog}
                onChange={(e) => updateFormData(e)}
                aria-label="Default select example"
              >
                <option value="Experiences">Experiences</option>
                <option value="Method">Method</option>
                <option value="Technology">Technology</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {/* ********Description****** */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>
            Description <sup className="required-icon">*</sup>{" "}
          </Form.Label>
          <Form.Control
            as="textarea"
            required
            type="text"
            name="docDesc"
            value={formData.docDesc}
            placeholder="Enter File Descrption"
            onChange={(e) => updateFormData(e)}
            disabled={isUploaded}
            size="sm"
          />
        </Form.Group>
        <Row>
          <Col md={8}>
            {/* *******Keywords******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Keywords</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Keywords"
                disabled={isUploaded}
                size="sm"
                onKeyPress={(e) => e.key === "Enter" && onKeyPressHandler(e)}
                onBlur={(e) => handleKeywords(e)}
              />
              {badges.map((badge, index) => {
                return (
                  <>
                    <Badge pill bg="primary">
                      {badge}
                      {"  "}
                      <MdCancel onClick={() => filterBagde(index)} />
                    </Badge>{" "}
                  </>
                );
              })}
            </Form.Group>
          </Col>
          <Col>
            {/* *******Attribution******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Attribution</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Attribution"
                name="attribution"
                value={formData.attribution}
                onChange={(e) => updateFormData(e)}
                size="sm"
                disabled={isUploaded}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* *******Published By******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                Published By{" "}
                {isRequired ? <sup className="required-icon">*</sup> : ""}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Published By"
                name="publishedBy"
                size="sm"
                value={formData.publishedBy}
                onChange={(e) => updateFormData(e)}
                disabled={isUploaded}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            {/* *******Published Dated******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Published Date <sup className="required-icon">*</sup></Form.Label>

              <Form.Control
                required
                type="date"
                placeholder="Date"
                name="publishedDate"
                size="sm"
                value={formData.publishedDate ? formData.publishedDate.substring(0, 10) : new Date().toISOString().substring(0, 10)}
                defaultValue={new Date().toISOString().substring(0, 10)}
                onChange={(e) => updateFormData(e)}
                disabled={isUploaded}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {/* *******Licence******* */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                Licence <sup className="required-icon">*</sup>{" "}
              </Form.Label>
              <Form.Select
                required
                name="licence"
                disabled={isUploaded}
                value={formData.licence}
                size="sm"
                onChange={(e) => UpdateLicence(e)}
                aria-label="Default select example"
              >
                {licenceArray.map((licence) => {
                  return <option value={licence}>{licence}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>{hasOtherLicence ? renderOtherLicence() : ""}</Col>
        </Row>
        {/* *******Submit******* */}
        <div className="control-buttons">
          {
            !isNewfile &&
            <Button onClick={() => replaceModal()} id="replace-btn" className="primary-btn" size="sm" >Replace</Button>

          }
          <Button form="document-form" variant="primary" className="primary-btn" type="submit" size="sm" onMouseOver={() => { changeFormData(); }} disabled={isUploaded}>
            Submit
          </Button>
          <Button onClick={() => { navigate(-1) }} className="primary-btn" size="sm" >Cancel</Button>

        </div>


      </Form>
      {/* <ModelComp show={modalShow}
         onHide={() => setModalShow(false)} /> */}
      <ReplaceModalComp show={hasReplace} formData={formData} setmainReload={setReload} onHide={() => sethasReplace(false)} />
    </>
  );

};



export { UploadFileComp };
