
export const getHeader = async (fileExtension) => {
  var headers = {}
  if (fileExtension === 'pdf') {
    return headers = { "Content-Type": "application/pdf" }
  }
  if (fileExtension === "csv") {
    return headers = { "Content-Type": "text/csv" }
  }
  if (fileExtension === "mp3") {
    return headers = { "Content-Type": "audio/mpeg3" }
  }
  if (fileExtension === "doc") {
    return headers = { "Content-Type": "application/msword" }
  }
  if (fileExtension === "docx") {
    return headers = {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
  }
  if (fileExtension === "mp4") {
    return headers = { "Content-Type": "video/mp4" }
  }
  if (fileExtension === "txt") {
    return headers = { "Content-Type": "text/plain" }
  }
  if (fileExtension === "xls") {
    return headers = { "Content-Type": "application/vnd.ms-excel" }
  }
  if (fileExtension === "xlsx") {
    return headers = { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
  }
  else {
    return {}
  }

}
