import axios from "axios";


// const baseUrl = "https://ed2b-106-51-129-143.in.ngrok.io";
const baseUrl = "https://space.societalthinking.org/api";

// const baseUrl = "http://localhost:8000"

// __ signin __

export const signInwithEmailAndPAssword = async (userData) => {
  let message = {
    request: {
      data: userData
    },
  };
  return await axios.post(`${baseUrl}/admin/v1/login/`, message);
}

export const getallDocs = async () => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/content/getAll`, { headers })
}

export const getallUsers = async () => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/get/users`, { headers })
}

export const getPresingedUrl = async (fileName, istranscript) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const Transcript = {
    request: {
      data: {
        TSfileName: fileName
      }
    }
  }
  const mp4file = {
    request: {
      data: {
        fileName
      }
    }
  }
  const data = istranscript ? Transcript : mp4file
  return await axios.post(`${baseUrl}/admin/v1/content/getUploadUrl`, data, { headers })
}


export const getallKeywords = async () => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/common/v1/content/searchKeywords`, { headers })
}

export const getallCollections = async () => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/collection/getAll`, { headers })
}

//content

export const getContent = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/content/viewIndividual/${id}`, { headers })
}

export const addContent = async (formData) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.post(`${baseUrl}/admin/v1/content/add`, data, { headers })
}

export const updateContent = async (formData, id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.put(`${baseUrl}/admin/v1/content/update/${id}`, data, { headers })
}

export const replaceContent = async (repalceFormdata, id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: repalceFormdata
    }
  }
  return await axios.post(`${baseUrl}/admin/v1/content/replace/${id}`, data, { headers })
}

export const updateKeywords = async (formData, id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.put(`${baseUrl}/admin/v1/keywords/update/${id}`, data, { headers })
}

export const addKeywords = async (title) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: {
        title: title
      }
    }
  }
  return await axios.post(`${baseUrl}/admin/v1/keywords/add`, data, { headers })
}

export const addBundel = async (formData) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.post(`${baseUrl}/admin/v1/bundle/create`, data, { headers })
}

export const UpdateBundel = async (formData, id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.put(`${baseUrl}/admin/v1/bundle/update/${id}`, data, { headers })
}

export const getBundle = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return await axios.get(`${baseUrl}/common/v1/bundle/${id}`, { headers })
}


export const getAllQuestions = async () => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/question`, { headers })
}

export const getIndividualQuestions = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/question/${id}`, { headers })
}

export const addQuestions = async (question) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: {
        question: question
      }
    }
  }
  return await axios.post(`${baseUrl}/admin/v1/question/add`, data, { headers })
}

export const editQuestions = async (formData, id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const data = {
    request: {
      data: formData
    }
  }
  return await axios.put(`${baseUrl}/admin/v1/question/update/${id}`, data, { headers })
}

export const getAllContactMessages = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.get(`${baseUrl}/admin/v1/contact/getMessages`, { headers })
}

export const deleteUser = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.delete(`${baseUrl}/admin/v1/user/delete/${id}`, { headers })
}

export const deleteCollection = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.delete(`${baseUrl}/admin/v1/bundle/delete/${id}`, { headers })
}

export const deleteDocument = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.delete(`${baseUrl}/admin/v1/content/delete/${id}`, { headers })
}

export const deleteMessage = async (id) => {
  const accessToken = localStorage.getItem('accessTokenAdmin')
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return await axios.delete(`${baseUrl}/admin/v1/contact/delete/${id}`, { headers })
}



