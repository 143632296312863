import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form, InputGroup, ProgressBar } from 'react-bootstrap';
import './popup.css'
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPresingedUrl, replaceContent, addContent, deleteDocument } from '../../apis';
import { getHeader } from '../helpers/getHeaders';
import axios, { CancelToken, isCancel } from "axios";
import { ClosableModelComp } from './closableModle';
import { MdCancel, MdOutlineCancel } from "react-icons/md";
const ReplaceModalComp = (props) => {
    const inputRef = useRef(null);
    const inputTransRef = useRef(null);
    const navigate = useNavigate()
    const { message, formData, show, setmainReload } = props
    const [obj1, setobj1] = useState();
    const [isMp4, setisMp4] = useState(false)
    const [repalceFormdata, setrepalceFormdata] = useState({})
    const [fileExtension, setfileExtension] = useState()
    const [viewType, setviewType] = useState();
    const [filePath, setfilePath] = useState()
    const [isuploaded, setisUploaded] = useState(false)
    const [isFileUploaded, setisFileUploaded] = useState(false);
    const [invalidExtension, setInvalidExtension] = useState(false)
    const [isBrowsefileFilled, setisBrowsefileFilled] = useState(false);
    const [uploadUrl, setUploadUrl] = useState("");
    const [fileName, setfileName] = useState("");
    const [modelmessage, setmodelmessage] = useState('')
    const [updatesuccess, setupdatesuccess] = useState(false)
    const [progress, setProgress] = useState();
    const [transcriptProgress, setTranscriptProgress] = useState();

    //transcript file state
    const [Transfilepath, setTransfilePath] = useState("");
    const [scriptFilename, setScriptfileName] = useState("");
    const [TransUrl, setTransUploadUrl] = useState("");
    const [TranscriptFileExt, setTranscriptFileExt] = useState();
    const [reload, setReload] = useState('')
    const cancelFileUpload = useRef(null);




    const [accept, setaccept] = useState(
        "application/pdf , .doc , .csv , .txt ,.xls ,.xlsx ,.pptx,.mp4,.mov,.mp3"
    );

    useEffect(() => {
        setrepalceFormdata(formData)
        setobj1(formData);
        if (formData.fileType === "mp4" || formData.fileType === "mp3" || formData.fileType === "mov") {
            setisMp4(true)
        }
        else {
            setisMp4(false)
        }
    }, [formData, reload])


    const getUploadUrl = async (e) => {
        if (e.target.value !== "") {
            setisBrowsefileFilled(true);
            const fileName = e.target.files[0].name;
            const fileExt = fileName.split(".").pop();
            setfileExtension(fileExt);

            fileExt === "mp4" || fileExt === "mov" ? setviewType("Watch") : fileExt === "mp3" ? setviewType("Listen") : setviewType("Read");
            // fileExt ==='mp4' ? setFormData({...formData , type:'watch'}) : setFormData({...formData , type:'read'})
            setfilePath(e.target.files[0]);
            setisMp4(fileExt === "mp4" || fileExt === "mov" || fileExt === "mp3");
            const istranscript = false
            getPresingedUrl(fileName, istranscript).then((res) => {

                if (res.status === 200) {

                    setisFileUploaded(false);

                    setUploadUrl(res.data.response.result.url);
                    setfileName(res.data.response.result.docName);
                    setInvalidExtension(false)
                    //  fileExt ==='mp4' ? setFormData({...formData , type:'watch'}) : setFormData({...formData , type:'read'})
                    setrepalceFormdata({
                        ...repalceFormdata,
                        fileName: res.data.response.result.docName,
                        type: fileExt === "mp4" || fileExt === "mov" ? "Watch" : fileExt === "mp3" ? "Listen" : "Read",
                    });
                }
            }).catch((err => {
                setInvalidExtension(true)
                setmodelmessage("Cannot Upload this type of Files")
                setupdatesuccess(true)
                // alert("Cannot Upload this type of Files")
            }));
        } else {
            setisBrowsefileFilled(false);
        }
    };

    const getTranscriptUrl = async (e) => {
        if (e.target.value !== "") {
            setisBrowsefileFilled(true);
            const fileName = e.target.files[0].name;
            setTranscriptFileExt(fileName.split(".").pop());
            setTransfilePath(e.target.files[0]);
            const istranscript = true
            getPresingedUrl(fileName, istranscript).then((res) => {
                if (res.status === 200) {

                    setTransUploadUrl(res.data.response.result.url);
                    setScriptfileName(res.data.response.result.docName);
                    setInvalidExtension(false)
                    setrepalceFormdata({
                        ...repalceFormdata,
                        scriptFileName: res.data.response.result.docName,
                    });
                }

            }
            ).catch((err) => {
                setInvalidExtension(true)
                setmodelmessage("Cannot Upload this type of Files")
                setupdatesuccess(true)
            })
        } else {
            setisBrowsefileFilled(false);
        }
    };

    const resetFileInput = () => {


        inputRef.current.value = null;
        // e.target.value = ""
        setisBrowsefileFilled(false);
        setrepalceFormdata({ ...repalceFormdata, fileName: '' });


    }

    const resetTranscriptFileInput = () => {

        inputTransRef.current.value = null;
        setrepalceFormdata({ ...repalceFormdata, scriptFileName: '' });

    }


    const handelUpload = async (e) => {
        e.preventDefault();
        //main file
        const contenType = await getHeader(fileExtension);
        const options = {
            headers: contenType,
            onUploadProgress: (data) => {
                setProgress(Math.round((100 * data.loaded) / data.total));
            },
            cancelToken: new CancelToken(
                (cancel) => (cancelFileUpload.current = cancel)
            ),
        };
        await axios
            .put(uploadUrl, filePath, options)
            .then((res) => {
                if (res.status === 200) {
                    //uploading transcript fle
                    const contenType = getHeader(TranscriptFileExt);
                    const options = {
                        headers: contenType,
                        onUploadProgress: (data) => {
                            setTranscriptProgress(Math.round((100 * data.loaded) / data.total));
                        },
                        cancelToken: new CancelToken(
                            (cancel) => (cancelFileUpload.current = cancel)
                        ),
                    }
                    if (scriptFilename !== "") {
                        axios.put(TransUrl, Transfilepath, options).then((res) => {
                            if (res.status === 200) {
                                setisUploaded(false);
                                setProgress();
                                setTranscriptProgress()
                                setisFileUploaded(true);
                                setmodelmessage("Document Uploaded With Transcript File Successfully")
                                setupdatesuccess(true)
                            }
                        });
                    } else {
                        setisUploaded(false);
                        setProgress();
                        setTranscriptProgress()
                        setisFileUploaded(true);
                        setmodelmessage("Document Uploaded  Successfully")
                        setupdatesuccess(true)
                    }
                }
            })
            .catch((err) => {
                if (isCancel(err)) {

                    setProgress();
                    setTranscriptProgress()
                }
            });
    };

    const CancelUpload = () => {

        if (cancelFileUpload.current) {
            cancelFileUpload.current("user has canceled the file upload");
        }
    };
    const handelSubmit = async () => {

        const prunedObj = (({ _id, createdBy, createdAt, isActive, __v, ...rest }) => rest)(repalceFormdata);

        await addContent(prunedObj).then((res) => {
            if (res.status === 200) {
                deleteDocument(formData._id).then((res) => {
                    if (res.status === 200) {
                        setmodelmessage("Document Replaced Successfuly")
                        setupdatesuccess(true)
                        props.onHide()
                        setTimeout(() => {
                            navigate('/dashboard/viewfiles')
                        }, [2000])
                    }
                })
            }
        });
    }


    const closeModle = () => {
        setfileName("")
        setisBrowsefileFilled(false)
        setScriptfileName("")
        setReload(Math.random())
        props.onHide()
    }
    return (
        <>
            <Form>
                {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={modelmessage} />}

                <Modal
                    show={show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Header style={{ display: 'block' }}>
                        <div>Replace Document</div>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={(e) => { handelUpload(e) }} >
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Select File </Form.Label>
                                <InputGroup size="sm" >
                                    <Form.Control
                                        ref={inputRef}
                                        name="file"
                                        type="file"
                                        size="sm"
                                        accept={accept}
                                        onChange={(e) => getUploadUrl(e)}
                                        disabled={false}
                                        required
                                    />
                                    {isBrowsefileFilled && <InputGroup.Text onClick={!isFileUploaded && resetFileInput} size="sm" id="basic-addon1"><MdOutlineCancel /></InputGroup.Text>}
                                </InputGroup>
                                <span>{isBrowsefileFilled && repalceFormdata.fileName}</span>
                                <div>
                                    {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                                </div>
                            </Form.Group>

                            {isMp4 && <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Select Transcript File </Form.Label>
                                <InputGroup size="sm" >
                                    <Form.Control
                                        // ref={inputTransRef}
                                        name="file"
                                        type="file"
                                        size="sm"
                                        accept=".doc,.docx,application/pdf"
                                        onChange={(e) => getTranscriptUrl(e)}
                                        disabled={false}
                                    />
                                    {isBrowsefileFilled && <InputGroup.Text onClick={!isFileUploaded && resetTranscriptFileInput} size="sm" id="basic-addon1"><MdOutlineCancel /></InputGroup.Text>}
                                </InputGroup>
                                <span>{scriptFilename}</span>
                                <div>
                                    {transcriptProgress && (
                                        <ProgressBar now={transcriptProgress} label={`${progress}%`} />
                                    )}
                                </div>
                            </Form.Group>}
                            <Button
                                variant="primary"
                                type="submit"
                                className="primary-btn"
                                disabled={isFileUploaded || progress || invalidExtension || !isBrowsefileFilled}
                                size="sm"
                            >
                                Upload File
                            </Button>
                            <Button
                                className="primary-btn"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    CancelUpload();
                                }}
                                disabled={isFileUploaded}
                                size="sm"
                            >
                                Cancel
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button className='primary-btn' size="sm" onClick={() => { handelSubmit() }} disabled={!isFileUploaded} type='button'>Submit</Button>
                        <Button className='primary-btn' type="submit" size="sm" onClick={closeModle} >Close</Button>
                    </Modal.Footer>

                </Modal>
            </Form>
        </>
    );
}

export { ReplaceModalComp }