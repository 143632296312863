import React, { useEffect, useState } from 'react';
// import { Container , Button, Table} from 'react-bootstrap';
import { getallDocs } from '../../apis';
import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col } from 'react-bootstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate, useLocation } from 'react-router-dom'
import '../../components/manage/files/files.css'
import { SpinnerComp } from '../spinner';
import Switch from "react-switch";
const { SearchBar } = Search;

const TableComp = (props) => {
  const { setdocIds, setdocBatch, docBatch, docIds } = props
  const { state } = useLocation();
  const [checked, setchecked] = useState([]);
  const [showLoader, setshowLoader] = useState(false)
  const [docs, setDocs] = useState([])
  const [currentpageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setSizeperPage] = useState(10)

  useEffect(() => {
    // setshowLoader(true)
    if (state) {
      if (state.docs.length === 0) {
        setshowLoader(true)
        getallDocs().then((res) => {
          const filesData = res.data.response.result.data.docs
          setDocs(filesData ? filesData : [])
          setshowLoader(false)
        })
      }
      else {
        setDocs(state.docs ? state.docs : [])
        setshowLoader(false)
      }

    }

  }, [state])

  useEffect(() => {
    setchecked(docIds)
  }, [docIds])




  const handleChange = (e, id, title) => {
    if (e) {
      setdocBatch(docBatch => [...docBatch, title])
      setdocIds(docIds => [...docIds, id])
    }
    if (!e) {
      const newArrayofBatch = docBatch.filter((x) => x !== title)
      const newArrayofIds = docIds.filter((x) => x !== id)
      setdocBatch(newArrayofBatch)
      setdocIds(newArrayofIds)
    }
    //array of checked ids 
    const checkedIds = [...checked]
    const index = checkedIds.indexOf(id)
    if (index !== -1) {
      checkedIds.splice(index, 1)
    } else {
      checkedIds.push(id)
    }
    setchecked(checkedIds);
  };



  const formaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <>
        <Switch
          key={row._id}
          onChange={(e) => handleChange(e, row._id, row.docTitle)}
          checked={extraData.includes(row._id)}
          className="react-switch"
          width={40}
          height={20}
          onColor={'#925eb0'}
          activeBoxShadow={'0 0 2px 3px #9204b0'}
        />
      </>
    )
  }

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPageNum(page)
      setSizeperPage(sizePerPage)
    },
    onPageChange: (page, sizePerPage) => {
      setCurrentPageNum(page)
      console.log(page)
      setSizeperPage(sizePerPage)

    }
  };

  const columns = [
    {
      dataField: 'sl.no',
      text: 'SL NO.',
      formatter: (cell, row, rowIndex, page) => {
        const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
        return serialNum
      },
      sort: true,
      headerStyle: {
        width: '40px'
      }
    },
    {
      dataField: 'docTitle',
      text: 'Title',
      style: {
        fontSize: '12px'
      }
    }, {
      dataField: 'views',
      text: 'Views',
      style: {
        fontSize: '12px'
      }
    }, {
      dataField: 'downloads',
      text: ' Downloads',
      style: {
        fontSize: '12px'
      }
    },
    {
      dataField: 'fileType',
      text: ' FileType',
      style: {
        fontSize: '12px'
      }
    },
    {
      dataField: 'isActive',
      text: 'IsActive',
      style: {
        fontSize: '12px'
      }
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: ' Actions',
      formatter: formaterHandler,
      formatExtraData: checked,
    }
  ];

  return (
    <>
      <SpinnerComp Show={showLoader} />
      <ToolkitProvider
        keyField="id"
        data={docs}
        columns={columns}

        search
      >
        {
          props => (
            <div>

              <Row  >
                <Col style={{ marginBottom: '1rem' }} >
                  <SearchBar {...props.searchProps} className="searchBar" />
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }} >
                  {/* <Button variant="primary" onClick={()=>navigate('/dashboard/uploadFiles',{state:{id:null,isNewfile:true}})}>Add Files</Button> */}
                </Col>
              </Row>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
  )
};

export { TableComp }