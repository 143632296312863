import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { Row, Col } from 'react-bootstrap';
import { TiTick } from 'react-icons/ti'
import { MdCancel } from 'react-icons/md'
import './popup.css'
import { deleteUser, deleteCollection, deleteDocument, deleteMessage } from "../../apis/"
import { useState } from 'react';

const ConfirmationModleComp = (props) => {
    const [success, setsuccess] = useState(false)
    const [deleteError, setdeleteError] = useState(false)
    const { message, id, setReload, method } = props
    const deleteuser = () => {
        if (method === "user") {
            deleteUser(id).then((res) => {
                if (res.status === 200) {
                    setsuccess(true)
                }
            }).catch((err) => {
                setsuccess(true)
                setdeleteError(true)
            })
        }
        if (method === "collection") {
            deleteCollection(id).then((res) => {
                if (res.status === 200) {
                    setsuccess(true)
                }
            }).catch((err) => {
                setsuccess(true)
                setdeleteError(true)

            })
        }
        if (method === "document") {
            deleteDocument(id).then((res) => {
                if (res.status === 200) {
                    setsuccess(true)
                }
            }).catch((err) => {
                setsuccess(true)
                setdeleteError(true)

            })
        }

        if (method === "message") {
            deleteMessage(id).then((res) => {
                if (res.status === 200) {
                    setsuccess(true)
                }
            }).catch((err) => {
                setsuccess(true)
                setdeleteError(true)

            })
        }
    }

    if (success) {
        setTimeout(() => {
            props.onHide()
            setReload(Math.random())
        }, [1000])
    }


    const normalModal = () => {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='small-modal'
            >
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer className='fotter-modal'>
                    <Button className='primary-btn' size="sm" onClick={deleteuser}>Yes</Button>
                    <Button className='primary-btn' size="sm" onClick={props.onHide}>No</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    const successModal = () => {
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='small-modal'
            >
                {!deleteError ? <Modal.Body> <TiTick className='tick-icon' /> Successfully Deleted </Modal.Body> : <Modal.Body> <MdCancel className='error-icon' /> Somthing Went Wrong</Modal.Body>}
            </Modal>
        )
    }
    return (
        <>
            {success ? successModal() : normalModal()}
        </>
    );
}

export { ConfirmationModleComp }