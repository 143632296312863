import React, { useEffect, useState } from 'react';
// import { Container , Button, Table} from 'react-bootstrap';
import { getallDocs, updateContent } from '../../../apis';
import '../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next';
// import '../../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Row, Col } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill, BsPlus } from 'react-icons/bs'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate, useLocation } from 'react-router-dom'
import { FaEdit, FaEye } from "react-icons/fa";
import { BiExport } from 'react-icons/bi'
import { MdDelete } from 'react-icons/md'

import './files.css'
import { SpinnerComp } from '../../../pages/spinner';
import { ViewCollectionDetails } from '../../../pages/helpers/viewcollectiondetails';
import { ClosableModelComp } from '../../../pages/popup/closableModle';
import { ConfirmationModleComp } from '../../../pages/popup/confirmationbox';
import Switch from "react-switch";
// import { data } from 'jquery';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const ViewfilesComp = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const [reload, setReload] = useState('')
  const [showLoader, setshowLoader] = useState(false)
  const [docs, setDocs] = useState([])
  const [updatesuccess, setupdatesuccess] = useState(false)
  const [modelmessage, setmodelmessage] = useState('')
  const [confirmation, setConfirmation] = useState([false, ''])
  const [currentpageNum, setCurrentPageNum] = useState(1)
  const [pageSize, setSizeperPage] = useState(10)

  useEffect(() => {
    setshowLoader(true)

    if (state === null) {
      getallDocs().then((res) => {
        const filesData = res.data.response.result.data.docs
        setDocs(filesData ? filesData : [])
        setshowLoader(false)
      })
    }
    else {
      setDocs(state.docs ? state.docs.docIds : [])
      setshowLoader(false)
    }

  }, [reload, state])

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const ids = docs.map((doc) => {
      if (doc.isActive) {
        return doc._id
      }
    })
    const finalIds = ids.filter((id) => id !== undefined)
    setchecked(finalIds)
  }, [docs])

  const handleChange = (id, isActive) => {
    const checkedIds = [...checked]
    const index = checkedIds.indexOf(id)
    if (index !== -1) {
      checkedIds.splice(index, 1)
    } else {
      checkedIds.push(id)
    }
    setchecked(checkedIds);
    const toggle = !isActive

    updateContent({ isActive: toggle }, id).then((res) => {
      if (res.status === 200) {
        setmodelmessage("Document Updated Successfully")
        setupdatesuccess(true)
        setReload(true)
      }
    })

  };

  const [checked, setchecked] = useState([]);

  const formaterHandler = (cellContent, row, rowIndex, extraData) => {
    return (
      <div className='actions'>
        {!state && <FaEdit className='edit-icon' onClick={() => { navigate(`/dashboard/uploadFiles`, { state: { id: row._id, isNewfile: false } }) }} />}
        {!state &&
          <Switch
            key={row._id}
            onChange={() => handleChange(row._id, row.isActive)}
            checked={extraData.includes(row._id)}
            className="react-switch-bg"
            width={40}
            height={20}
            onColor={'#925eb0'}
            activeBoxShadow={'0 0 2px 3px #9204b0'}
          />
        }
        <FaEye className='view-icon' onClick={() => { navigate(`/dashboard/viewIndividualFiles`, { state: { id: row._id, isfromCollection: state ? true : false } }) }} />
        {!state && <MdDelete className='delete-icon' onClick={() => { deletUser(row._id) }} />}
      </div>
    )
  }

  const deletUser = (id) => {
    setConfirmation([true, id])
  }


  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPageNum(page)
      setSizeperPage(sizePerPage)
    },
    onPageChange: (page, sizePerPage) => {
      setCurrentPageNum(page)
      console.log(page)
      setSizeperPage(sizePerPage)

    }
  };

  const columns = [
    {
      dataField: 'sl.no',
      text: 'SN.',
      formatter: (cell, row, rowIndex, page) => {
        const serialNum = (pageSize * (currentpageNum - 1) + rowIndex + 1)
        return serialNum
      },
      // formatExtraData: paginationFactory(),
      headerStyle: {
        width: '40px'
      }
    },
    {
      dataField: 'docTitle',
      text: 'Title',
      style: {
        fontSize: '13px'
      },
      sort: true
    }, {
      dataField: 'views',
      text: 'Views',
      style: {
        fontSize: '12px'
      },
      sort: true,
      formatter: (value, row) => (
        <span className='small-text' >{value ? value : "No Views"}</span>
      )

    }, {
      dataField: 'downloads',
      text: ' Downloads',
      style: {
        fontSize: '12px'
      },
      formatter: (value, row) => (
        <span className='small-text'  >{value ? value : 0}</span>
      ),
      sort: true,

    },
    {
      dataField: 'fileType',
      text: 'File Type',
      style: {
        fontSize: '12px'
      },
      sort: true,

    },
    {
      dataField: 'catalog',
      text: 'Catalog',
      style: {
        fontSize: '12px'
      },
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Type',
      style: {
        fontSize: '12px'
      },
      sort: true,
    },
    {
      dataField: 'df1',
      isDummyField: true,
      text: ' Actions',
      formatter: formaterHandler,
      formatExtraData: checked,
      style: {
        fontSize: '12px'
      }
    }
  ];

  return (
    <>
      {confirmation[0] && <ConfirmationModleComp show={confirmation[0]} setReload={setReload} id={confirmation[1]} method={"document"} message={"Do You want to Delete this Document"} onHide={() => setConfirmation([false, ''])} />}
      {updatesuccess && <ClosableModelComp showValue={updatesuccess} setupdatesuccess={setupdatesuccess} message={modelmessage} />}
      <SpinnerComp Show={showLoader} />
      <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate(state ? '/dashboard/viewcollections' : '/dashboard/manage')} noDataIndication="Table is Empty" />
      <span className='dashboard-title'>{state ? 'Collection Deatils' : 'Documents'}</span>
      <ToolkitProvider
        keyField="id"
        data={docs}
        columns={columns}
        search
        exportCSV
      >
        {
          props => (
            <div>
              <Row  >
                <div>{state && <ViewCollectionDetails data={state} />}</div>
                <div className='details-header' >{state && "Selected Documents"}</div>
                <Col md={7}>
                  <SearchBar {...props.searchProps} className="searchBar" />
                </Col>
                <Col className='export-class  ' >
                  {!state && <ExportCSVButton className="export-btn" {...props.csvProps}><BiExport className='export-icon' />Export CSV</ExportCSVButton>}
                  {!state && <Button variant="primary" size="sm" className='primary-btn' onClick={() => navigate('/dashboard/uploadFiles', { state: { id: null, isNewfile: true } })}><BsPlus className='plus-icon' />{' '}Add Document</Button>}
                </Col>
              </Row>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                bootstrap4
                keyField='_id'
                condensed
                className="Bootstrap-table"
                size="sm"
                bodyStyle={{ overflow: 'overlay' }}
                wrapperClasses="table-responsive"
              />
            </div>
          )
        }
      </ToolkitProvider>
    </>
  )
};

export { ViewfilesComp }