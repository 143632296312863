import {Spinner} from 'react-bootstrap'
import './spinner.css'
const SpinnerComp = (props) =>{
    const {Show} = props
    if(Show){
        return <Spinner animation="border"  className='spinner' />
    }
    else{
        return ''
    }
}

const SmallSpinnerComp = (props) =>{
    const {Show} = props
    if(Show){
        return <Spinner style={{color:'purple'}} animation="border" />
    }
    else{
        return ''
    }
}

export {SpinnerComp,SmallSpinnerComp}