import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { getContent } from '../../../../apis'
import './view.css'
import { Button } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
const ViewIndividualComp = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [file, setFile] = useState({})
    const [downloadUrl, setdownloadUrl] = useState()

    useEffect(() => {
        const { id } = state

        getContent(id).then((res) => {
            setFile(res.data.response.result.data)
            setdownloadUrl(res.data.response.result.downloadUrl)
        })

    }, [state])

    const convertedDate = (new Date(file.publishedDate).toLocaleDateString())
    return (
        <>
            <BsFillArrowLeftCircleFill className="back-button" onClick={() => navigate(state && state ? -1 : '/dashboard/viewfiles')} />
            <span className='dashboard-title'>
                {file.docTitle}
            </span>
            <div className='table-div'>

                <div className='view-btn' >
                    <Button className='primary-btn' size="sm" href={downloadUrl} target="_blank" >View File</Button>
                </div>
                <Table striped bordered hover size="sm">

                    <tbody>
                        <tr>
                            <th>Title</th>
                            <td>{file.docTitle}</td>
                        </tr>
                        <tr>
                            <th>File Name</th>
                            <td>{file.fileName}</td>
                        </tr>

                        <tr>
                            <th>Description</th>
                            <td>{file.docDesc}</td>

                        </tr>
                        <tr>
                            <th>Keywords</th>
                            <td>{file.keywords ? file.keywords.join(' ') : []}</td>

                        </tr>
                        <tr>
                            <th>Attributions</th>
                            <td>{file.attribution}</td>
                        </tr>
                        <tr>
                            <th>PublishedDate</th>
                            <td>{convertedDate}</td>
                        </tr>
                        <tr>
                            <th>PublishedBy</th>
                            <td>{file.publishedBy}</td>

                        </tr>
                        <tr>
                            <th>Licence</th>
                            <td>{file.licence}</td>
                        </tr>
                        <tr>
                            <th>Catalogue</th>
                            <td>{file.catalog}</td>
                        </tr>
                        <tr>
                            <th>Views</th>
                            <td>{file.views}</td>
                        </tr>

                    </tbody>
                </Table>
            </div>
        </>
    )
}

export { ViewIndividualComp }